import React from "react";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Typography,
  Grid,
  Avatar,
} from "@mui/material";
import Chart from "react-apexcharts";
import TrendingUpRoundedIcon from "@mui/icons-material/TrendingUpRounded";
import TrendingDownRoundedIcon from "@mui/icons-material/TrendingDownRounded";

const FirmDash = () => {
  const chartOptions = {
    chart: {
      type: "area",
      toolbar: { show: false },
    },
    dataLabels: { enabled: false },
    stroke: { curve: "smooth" },
    xaxis: {
      categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"],
    },
  };

  const chartSeries = [
    {
      name: "Revenue",
      data: [12000, 15000, 13000, 17000, 19000, 22000, 25000],
    },
  ];

  return (
    <Box p={3}>
      <Typography variant="h2">This ui will be change it just dummy</Typography>
      <Grid container spacing={3}>
        {/* Revenue Card */}
        <Grid item xs={12} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h6">Total Revenue</Typography>
              <Typography variant="h4" fontWeight={600}>
                $25,000
              </Typography>
              <Typography
                color="success.main"
                display="flex"
                alignItems="center"
              >
                <TrendingUpRoundedIcon sx={{ fontSize: "1rem" }} /> 12% Increase
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* Case Overview */}
        <Grid item xs={12} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h6">Active Cases</Typography>
              <Typography variant="h4" fontWeight={600}>
                15
              </Typography>
              <Typography color="error.main" display="flex" alignItems="center">
                <TrendingDownRoundedIcon fontSize="1rem" /> 5% Decrease
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* Clients Count */}
        <Grid item xs={12} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h6">Total Clients</Typography>
              <Typography variant="h4" fontWeight={600}>
                120
              </Typography>
              <Typography
                color="success.main"
                display="flex"
                alignItems="center"
              >
                <TrendingUpRoundedIcon fontSize="1rem" /> 8% Increase
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Revenue Chart */}
      <Box mt={4}>
        <Card>
          <CardHeader title="Revenue Growth" />
          <CardContent>
            <Chart
              options={chartOptions}
              series={chartSeries}
              type="area"
              height={300}
            />
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

export default FirmDash;
