import { useState, useEffect } from "react";
import { Box, Typography, IconButton } from "@mui/material";
import { motion } from "framer-motion";
import PlayArrowRoundedIcon from "@mui/icons-material/PlayArrowRounded";
import PauseRoundedIcon from "@mui/icons-material/PauseRounded";
import RestartAltRoundedIcon from "@mui/icons-material/RestartAltRounded";
import { useDialog } from "../../DialogContext";
import ServiceTimeForm from "../Activities/ActivitiesForms/ServiceTimeForm";

const Stopwatch = () => {
  const { showDialog } = useDialog();
  const [time, setTime] = useState(0);
  const [isRunning, setIsRunning] = useState(false);
  const [startTime, setStartTime] = useState(null);
  let timer;

  useEffect(() => {
    const savedTime = localStorage.getItem("stopwatch-time");
    const savedIsRunning = localStorage.getItem("stopwatch-is-running");
    const savedStartTime = localStorage.getItem("stopwatch-start-time");

    if (savedTime) {
      setTime(parseInt(savedTime, 10));
    }

    if (savedIsRunning === "true") {
      setIsRunning(true);

      if (savedStartTime) {
        setStartTime(new Date(savedStartTime));
      }

      if (savedStartTime && savedTime) {
        const now = new Date();
        const lastStartTime = new Date(savedStartTime);
        const savedTimeInSeconds = parseInt(savedTime, 10);

        const elapsedSinceLastStart = Math.floor((now - lastStartTime) / 1000);
        const totalTimeToSet = savedTimeInSeconds + elapsedSinceLastStart;

        setTime(totalTimeToSet);
        localStorage.setItem("stopwatch-time", totalTimeToSet.toString());
      }
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("stopwatch-is-running", isRunning.toString());

    if (isRunning) {
      if (startTime) {
        localStorage.setItem("stopwatch-start-time", startTime.toISOString());
      }

      timer = setInterval(() => {
        setTime((prevTime) => {
          const newTime = prevTime + 1;
          localStorage.setItem("stopwatch-time", newTime.toString());
          return newTime;
        });
      }, 1000);
    } else {
      clearInterval(timer);
    }

    return () => clearInterval(timer);
  }, [isRunning, startTime]);

  const handleStartStop = () => {
    if (isRunning) {
      const endTime = new Date();

      showDialog(
        <ServiceTimeForm
          timeIn={startTime}
          timeOut={endTime}
          elapsedTime={formatTime(time)}
        />,
        {
          title: "Edit time entry",
        }
      );
      //reset the time
      setTime(0);
      setStartTime(null);
      localStorage.removeItem("stopwatch-time");
      localStorage.removeItem("stopwatch-is-running");
      localStorage.removeItem("stopwatch-start-time");
    } else {
      const now = new Date();
      setStartTime(now);
    }
    setIsRunning(!isRunning);
  };

  const formatTime = (seconds) => {
    const hrs = String(Math.floor(seconds / 3600)).padStart(2, "0");
    const mins = String(Math.floor((seconds % 3600) / 60)).padStart(2, "0");
    const secs = String(seconds % 60).padStart(2, "0");
    return `${hrs}:${mins}:${secs}`;
  };

  return (
    <motion.div
      drag
      dragConstraints={{
        top: 0,
        left: 0,
        right: window.innerWidth - 150,
        bottom: window.innerHeight - 70,
      }}
      style={{
        position: "sticky",
        top: "0rem",
        right: "0rem",
        zIndex: 99,
        cursor: "grab",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "0.5rem",
          borderRadius: "5rem",
          border: "1px solid #FFFFFF2E",
          background: "#FFFFFF40",
          boxShadow: "0 0rem 0.2rem 0 #1F268780",
          backdropFilter: "blur(4px)",
          WebkitBackdropFilter: "blur(4px)",
        }}
      >
        <IconButton
          sx={{ color: isRunning ? "success.main" : "white.main" }}
          onClick={handleStartStop}
        >
          {isRunning ? <PauseRoundedIcon /> : <PlayArrowRoundedIcon />}
        </IconButton>
        <Typography
          variant="body1"
          sx={{
            fontWeight: 600,
            minWidth: "6.5rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {formatTime(time)}
        </Typography>
        <IconButton
          color="secondary"
          onClick={() => {
            setTime(0);
            localStorage.setItem("stopwatch-time", "0");
          }}
        >
          <RestartAltRoundedIcon />
        </IconButton>
      </Box>
    </motion.div>
  );
};

export default Stopwatch;
