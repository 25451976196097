import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Slide,
  TextField,
  Typography,
} from "@mui/material";
import PersonalDash from "../../components/LawyerCompo/Dashboard/PersonalDash";
import { pageScrollStyle } from "../../styles/styles";
import FirmDash from "../../components/LawyerCompo/Dashboard/FirmDash";
import KeyboardArrowLeftRoundedIcon from "@mui/icons-material/KeyboardArrowLeftRounded";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import CreateRoundedIcon from "@mui/icons-material/CreateRounded";
import { CloseRounded } from "@mui/icons-material";
import { Controller, useForm } from "react-hook-form";
import axios from "axios";
import { backend_api } from "../../static/server";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import AddPhotoAlternateRoundedIcon from "@mui/icons-material/AddPhotoAlternateRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import { FileUploader } from "react-drag-drop-files";
import { useNavigate } from "react-router-dom";
import { getFirmData } from "../../redux/Apps/Lawyerapp/DashboardSlice/DashboardSlice";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export const FILE_TYPES = [
  "JPEG",
  "PNG",
  "GIF",
  "BMP",
  "ICO",
  "SVG",
  "JPG",
  "TIFF",
  "WEBP",
];

const DashboardPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showDash, setShowDash] = useState(1);
  const [collapsed, setCollapsed] = useState(false);
  const [openEditFirmForm, setOpenEditFirmForm] = useState(false);
  const [firmLogo, setFirmLogo] = useState(null);
  const [firmLogoFile, setFirmLogoFile] = useState(null);
  const { token } = useSelector((data) => data.user);
  const { firmData } = useSelector((state) => state.dashboard);

  useEffect(() => {
    dispatch(
      getFirmData({
        url: `${backend_api}firm/create-law-firm/`,
        token: token,
      })
    );
  }, [dispatch, token]);

  const onlyFirstFirm =
    Array.isArray(firmData) && firmData.length > 0 ? firmData[0] : null;

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    watch,
    formState: { errors },
  } = useForm();

  const getBtnActiveStyle = (key) => ({
    textTransform: "capitalize",
    bgcolor: showDash === key ? "primary.main" : "primary",
    color: showDash === key ? "#fff" : "primary",
  });
  const toggleCollapse = () => {
    setCollapsed((prev) => !prev);
  };

  const onEditFirmSubmit = async (data) => {
    const formData = new FormData();

    Object.keys(data).forEach((key) => {
      if (
        data[key] !== undefined &&
        data[key] !== null &&
        data[key] !== "" &&
        key !== "firm_logo"
      ) {
        formData.append(key, data[key]);
      }
    });

    if (firmLogoFile) {
      formData.append("firm_logo", firmLogoFile);
    }

    try {
      const response = await axios.patch(
        `${backend_api}firm/create-law-firm/${onlyFirstFirm?.id}/`,
        formData,
        {
          headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response) {
        toast.success("Law firm edited successfully");
        setOpenEditFirmForm(false);
        dispatch(
          getFirmData({
            url: `${backend_api}firm/create-law-firm/`,
            token: token,
          })
        );
      }
    } catch (error) {
      toast.error(
        error.response?.data?.message ||
          "Failed to edit firm. Please try again."
      );
    }
  };
  const handleEditFirmForm = () => {
    setOpenEditFirmForm(true);

    if (onlyFirstFirm) {
      const allowedKeys = [
        "name",
        "firm_size",
        "firm_logo",
        "date_of_establishment",
        "firm_web_url",
        "firm_mobile_no",
        "firm_email",
        "address",
        "city",
        "state",
        "pin_code",
        "country",
      ];

      Object.keys(onlyFirstFirm)?.forEach((key) => {
        if (allowedKeys.includes(key)) {
          setValue(key, onlyFirstFirm[key]);
        }
      });

      if (onlyFirstFirm?.firm_logo) {
        setFirmLogo(onlyFirstFirm?.firm_logo);
      }
    }
  };

  const handleFileChange = (file) => {
    if (file && FILE_TYPES.includes(file.type.split("/")[1].toUpperCase())) {
      setValue("firm_logo", file);
      setFirmLogo(URL.createObjectURL(file));
    } else {
      toast.error("Invalid file type! Please upload a valid image file.");
    }
  };
  const handleDeleteImage = () => {
    setFirmLogo(null);
    setFirmLogoFile(null);
    setValue("firm_logo", null);
  };

  return (
    <>
      <Box>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
          <div className="bg-white rounded-lg items-center shadow-md p-2">
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box
                sx={{ display: "flex", alignItems: "center", gap: "0.5rem" }}
              >
                {!collapsed && (
                  <Box sx={{ display: "flex", gap: "1rem" }}>
                    <Button
                      variant="text"
                      onClick={() => setShowDash(1)}
                      sx={getBtnActiveStyle(1)}
                      size="small"
                    >
                      Personal Dashboard
                    </Button>
                    <Button
                      variant="text"
                      onClick={() => {
                        setShowDash(2);
                        toggleCollapse();
                      }}
                      sx={getBtnActiveStyle(2)}
                      size="small"
                      disabled={firmData?.length === 0}
                    >
                      Firm Dashboard
                    </Button>
                    <Button
                      variant="text"
                      onClick={() => setShowDash(3)}
                      sx={getBtnActiveStyle(3)}
                      size="small"
                    >
                      Activities
                    </Button>
                  </Box>
                )}
                <IconButton onClick={toggleCollapse} size="small">
                  {collapsed ? (
                    <KeyboardArrowRightRoundedIcon
                      sx={{ fontSize: "0.9rem" }}
                    />
                  ) : (
                    <KeyboardArrowLeftRoundedIcon sx={{ fontSize: "0.9rem" }} />
                  )}
                </IconButton>
              </Box>
              {showDash === 2 && (
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box>
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: "1rem",
                        fontWeight: 600,
                        color: "text.primary",
                      }}
                    >
                      Law office of {onlyFirstFirm?.name}
                    </Typography>
                  </Box>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleEditFirmForm()}
                    size="small"
                    startIcon={<CreateRoundedIcon />}
                  >
                    Edit firm
                  </Button>
                </Box>
              )}
            </Box>
          </div>
          <Box
            className="bg-white rounded-lg items-center shadow-md p-3 h h-[82vh] overflow-auto"
            sx={pageScrollStyle}
          >
            {showDash === 1 && (
              <Box>
                <PersonalDash />
              </Box>
            )}
            {showDash === 2 && (
              <Box>
                <FirmDash />
              </Box>
            )}
            {showDash === 3 && <Box>Firm Feedback Content</Box>}
          </Box>
        </Box>

        {/* ------------------------- EDIT FIRM FORM ----------------- */}
        <Dialog
          open={openEditFirmForm}
          TransitionComponent={Transition}
          onClose={() => setOpenEditFirmForm(false)}
          PaperProps={{
            style: {
              padding: 0,
              width: "35rem",
            },
          }}
          sx={{
            "& .MuiDialog-paper": { ...pageScrollStyle },
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                px: "1rem",
                py: "0.5rem",
                bgcolor: "primary.main",
              }}
            >
              <Box
                sx={{ display: "flex", alignItems: "center", gap: "0.5rem" }}
              >
                <Typography
                  variant="body1"
                  color="white"
                  sx={{ fontSize: "1rem" }}
                >
                  Edit Firm
                </Typography>
              </Box>
              <IconButton
                edge="end"
                aria-label="close"
                color="white"
                onClick={() => setOpenEditFirmForm(false)}
              >
                <CloseRounded />
              </IconButton>
            </Box>
            <form onSubmit={handleSubmit(onEditFirmSubmit)}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                  p: "1rem",
                }}
              >
                <Controller
                  name="name"
                  control={control}
                  rules={{ required: "Firm name is required" }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={
                        <span>
                          Firm name <span style={{ color: "red" }}>*</span>
                        </span>
                      }
                      size="small"
                      fullWidth
                      error={!!errors.firm_name}
                      helperText={errors.firm_name?.message}
                    />
                  )}
                />
                <Controller
                  name="firm_size"
                  control={control}
                  rules={{ required: "Firm size is required" }}
                  render={({ field }) => (
                    <FormControl size="small" fullWidth>
                      <InputLabel sx={{ bgcolor: "#fff", px: "0.3rem" }}>
                        <span>
                          Firm Size <span style={{ color: "red" }}>*</span>
                        </span>
                      </InputLabel>
                      <Select {...field}>
                        <MenuItem value={1}>1</MenuItem>
                        <MenuItem value={4}>2-4</MenuItem>
                        <MenuItem value={9}>5-9</MenuItem>
                        <MenuItem value={19}>10-19</MenuItem>
                        <MenuItem value={60}>20-60</MenuItem>
                        <MenuItem value={200}>61-200</MenuItem>
                        <MenuItem value={500}>500+</MenuItem>
                      </Select>
                    </FormControl>
                  )}
                />
                <Box sx={{ display: "flex", gap: "1rem" }}>
                  <Box sx={{ width: "6rem" }}>
                    <Box sx={{ position: "relative" }}>
                      {firmLogo ? (
                        <Box
                          sx={{
                            position: "relative",
                            borderRadius: "1rem",
                            height: "6rem",
                            width: "6rem",
                            overflow: "hidden",
                            border: "1px dashed #dadada",
                          }}
                        >
                          <img
                            src={firmLogo}
                            alt="Firm Logo"
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                            }}
                          />
                          <Box
                            sx={{
                              position: "absolute",
                              top: 0,
                              left: 0,
                              width: "100%",
                              height: "100%",
                              bgcolor: "rgba(0,0,0,0)",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              transition: "background-color 0.3s",
                              "&:hover": {
                                bgcolor: "rgba(0,0,0,0.5)",
                                ".deleteBtn": {
                                  display: "flex",
                                },
                              },
                            }}
                          >
                            <IconButton
                              onClick={handleDeleteImage}
                              className="deleteBtn"
                              sx={{
                                display: "none",
                                "&:hover": { color: "red" },
                              }}
                            >
                              <DeleteRoundedIcon />
                            </IconButton>
                          </Box>
                        </Box>
                      ) : (
                        <FileUploader
                          handleChange={handleFileChange}
                          name="firm_logo"
                          types={FILE_TYPES}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                              border: "1px dashed #dadada",
                              p: "1rem",
                              borderRadius: "1rem",
                              height: "6rem",
                              width: "6rem",
                              textAlign: "center",
                            }}
                          >
                            <AddPhotoAlternateRoundedIcon
                              sx={{ fontSize: "2rem", color: "#6D6E6F" }}
                            />
                            <Typography variant="body2" color="text.secondary">
                              Upload logo
                            </Typography>
                          </Box>
                        </FileUploader>
                      )}
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "1rem",
                      width: "100%",
                    }}
                  >
                    <DatePicker
                      value={
                        watch("date_of_establishment")
                          ? dayjs(watch("date_of_establishment"))
                          : null
                      }
                      onChange={(newValue) =>
                        setValue(
                          "date_of_establishment",
                          newValue?.format("YYYY-MM-DD")
                        )
                      }
                      slotProps={{
                        textField: {
                          size: "small",
                          fullWidth: true,
                          label: "Date of Establishment",
                        },
                      }}
                    />

                    <Controller
                      name="website_url"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="Website URL"
                          size="small"
                          fullWidth
                        />
                      )}
                    />
                  </Box>
                </Box>

                <Box sx={{ display: "flex", gap: "1rem" }}>
                  <Controller
                    name="firm_mobile_no"
                    control={control}
                    rules={{
                      pattern: {
                        value: /^[0-9]{10}$/,
                        message: "Please enter a valid 10-digit mobile number",
                      },
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        size="small"
                        type="number"
                        fullWidth
                        label="Firm Mobile No."
                        error={!!errors?.firm_mobile_no}
                        helperText={errors?.firm_mobile_no?.message}
                      />
                    )}
                  />
                  <Controller
                    name="firm_email"
                    control={control}
                    rules={{
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "Invalid email address",
                      },
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Firm Email"
                        size="small"
                        fullWidth
                        error={!!errors.firm_email}
                        helperText={errors.firm_email?.message}
                      />
                    )}
                  />
                </Box>

                <Controller
                  name="address"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      size="small"
                      label="Firm Address"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <HomeRoundedIcon sx={{ color: "#DEDEDE" }} />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
                <Box sx={{ display: "flex", gap: "1rem" }}>
                  <Controller
                    name="city"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="City"
                        size="small"
                        fullWidth
                      />
                    )}
                  />
                  <Controller
                    name="state"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="State"
                        size="small"
                        fullWidth
                      />
                    )}
                  />
                </Box>

                <Box sx={{ display: "flex", gap: "1rem" }}>
                  <Controller
                    name="pin_code"
                    control={control}
                    // rules={{
                    //   required: "Pin code is required",
                    //   pattern: {
                    //     value: /^[0-9]{6}$/,
                    //     message: "Please enter a valid 6-digit pin code",
                    //   },
                    // }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Pin Code"
                        size="small"
                        fullWidth
                      />
                    )}
                  />
                  <Controller
                    name="country"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Country"
                        size="small"
                        fullWidth
                      />
                    )}
                  />
                </Box>

                <Button variant="contained" type="submit" color="primary">
                  Submit
                </Button>
              </Box>
            </form>
          </Box>
        </Dialog>
      </Box>
    </>
  );
};

export default DashboardPage;
