import React, { useEffect, useState } from "react";
import {
  Typography,
  Box,
  TextField,
  InputAdornment,
  IconButton,
  Button,
} from "@mui/material";
import { motion } from "framer-motion";
import { useForm } from "react-hook-form";
import RetroGridBg from "../RetroGridBg";
import { VisibilityOffRounded, VisibilityRounded } from "@mui/icons-material";
import LawyerGroupImg from "../../assets/lawyer-group.webp";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import { backend_api } from "../../static/server";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";
import companyLogo from "../../assets/nav-lexmom.webp";

const ResetPassForm = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [expiredLink, setExpiredLink] = useState(false);
  const [loading, setLoading] = useState(false);
  const token = searchParams.get("token");

  //Check Valid Link from email or not. If expired then show expired message
  useEffect(() => {
    const verifyResetLink = async () => {
      try {
        if (!token) {
          navigate("/");
          return;
        }

        const response = await axios.get(
          `${backend_api}user/forget-password/?verify_code=${token}`
        );
        if (response?.data?.expire) {
          toast.error("Link has expired", { toastId: "expire-toast" }); // Unique ID
          setExpiredLink(true);
        }
        if (response?.data?.verify) {
          toast.success("Link is verified", { toastId: "verify-toast" }); // Unique ID
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    verifyResetLink();
  }, [token, navigate]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
    trigger,
    setValue,
  } = useForm({
    defaultValues: {
      password: "",
      cnf_password: "",
    },
  });

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const body = new URLSearchParams();
      body.append("token_code", token);
      body.append("password", data?.password);

      const response = await axios.post(
        `${backend_api}user/forget-password/`,
        body
      );

      if (response.status === 200) {
        toast.success("Password reset sccessfully");
        setTimeout(() => {
          navigate("/login");
        }, 2000);
      }
    } catch (error) {
      console.error("Password reset failed", error);
      setLoading(false);
    }
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    setValue(name, value);
    trigger("cnf_password");
  };

  return (
    <div className="min-h-screen flex flex-col-reverse 800px:flex-row overflow-hidden">
      <motion.div
        initial={{ opacity: 0, x: 100 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.8 }}
        className="w-full 800px:w-1/2 relative text-white flex items-center justify-center flex-col p-5"
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            bgcolor: "#004242",
            borderRadius: "3rem",
          }}
        >
          <Box sx={{ height: "20rem" }}>
            <img
              src={LawyerGroupImg}
              alt="Side Img"
              style={{
                height: "100%",
                width: "100%",
                objectFit: "contain",
              }}
              onError={(e) => {
                e.onerror = null;
                e.target.src = require("../../assets/no-preview.webp");
              }}
            />
          </Box>

          {/* Overlay Information */}
          <div className="relative z-10 text-center mt-4">
            <h2 className="text-4xl font-bold">Welcome to Our Platform</h2>
            <p className="mt-4 text-lg">
              Join us and enjoy exclusive features designed just for you!
            </p>
          </div>
        </Box>
      </motion.div>
      <motion.div
        initial={{ opacity: 0, x: -100 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.8 }}
        className="w-full 800px:w-1/2 bg-white flex flex-col justify-center items-center"
      >
        <Box
          sx={{
            display: "flex",
            width: "100%",
            height: "100%",
            position: "relative",
            zIndex: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100%",
              position: "relative",
              zIndex: 3,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "0.1rem",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 4,
              }}
            >
              <Box sx={{ height: "2rem" }}>
                <img
                  src={companyLogo}
                  alt="Company Logo"
                  style={{
                    height: "100%",
                    width: "100%",
                    objectFit: "contain",
                  }}
                  onError={(e) => {
                    e.onerror = null;
                    e.target.src = require("../../assets/nav-lexmom.webp");
                  }}
                />
              </Box>
              <Typography
                variant="body1"
                color={expiredLink ? "error" : "primary"}
                sx={{ fontSize: "1.2rem", fontWeight: "500" }}
              >
                {expiredLink ? "Link Expired" : "Reset Password"}
              </Typography>
            </Box>
            {expiredLink ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "1rem",
                }}
              >
                <Typography variant="body2" color="text.secondary">
                  Your password reset link has{" "}
                  <span style={{ color: "#d32f2f" }}>expired</span>. Please
                  request a new.
                </Typography>

                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() => navigate("/login")}
                >
                  Login Page
                </Button>
              </Box>
            ) : (
              <Box sx={{ position: "relative", zIndex: 5 }}>
                <form
                  className="space-y-4 w-full max-w-sm"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <TextField
                    type={showPassword ? "text" : "password"}
                    label="New Password"
                    variant="outlined"
                    fullWidth
                    size="small"
                    {...register("password", {
                      required: "Password is required",
                      minLength: {
                        value: 6,
                        message: "Password must be at least 6 characters",
                      },
                    })}
                    error={!!errors.password}
                    helperText={errors.password?.message}
                    onChange={handlePasswordChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleClickShowPassword}
                            edge="end"
                            color="primary"
                          >
                            {showPassword ? (
                              <VisibilityOffRounded />
                            ) : (
                              <VisibilityRounded />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    type={showPassword ? "text" : "password"}
                    label="Confirm Password"
                    size="small"
                    variant="outlined"
                    fullWidth
                    {...register("cnf_password", {
                      required: "Please confirm your password",
                      validate: (value) =>
                        value === watch("password") || "Passwords do not match",
                    })}
                    error={!!errors.cnf_password}
                    helperText={errors.cnf_password?.message}
                    onChange={handlePasswordChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleClickShowPassword}
                            edge="end"
                            color="primary"
                          >
                            {showPassword ? (
                              <VisibilityOffRounded />
                            ) : (
                              <VisibilityRounded />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <LoadingButton
                    type="submit"
                    loading={loading}
                    loadingPosition="start"
                    variant="contained"
                    fullWidth
                    sx={{ p: "0.4rem", fontSize: "1rem" }}
                  >
                    Reset Password
                  </LoadingButton>
                </form>
              </Box>
            )}
          </Box>
          <Box
            sx={{
              position: "absolute",
              width: "100%",
              height: "100%",
              overflow: "hidden",
              zIndex: 1,
              top: 0,
              left: 0,
            }}
          >
            <RetroGridBg />
          </Box>
        </Box>
      </motion.div>
    </div>
  );
};

export default ResetPassForm;
