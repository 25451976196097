import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  TextField,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { FileUploader } from "react-drag-drop-files";
import AddPhotoAlternateRoundedIcon from "@mui/icons-material/AddPhotoAlternateRounded";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { CountrySelect } from "../../styles/styles";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import { genders } from "../../static/staticdata";
import { toast } from "react-toastify";
import { CloseRounded } from "@mui/icons-material";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import { useSelector } from "react-redux";
import axios from "axios";
import { backend_api, backend_url } from "../../static/server";
import { Navigate } from "react-big-calendar";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import { useNavigate } from "react-router-dom";

export const FILE_TYPES = [
  "JPEG",
  "PNG",
  "GIF",
  "BMP",
  "ICO",
  "SVG",
  "JPG",
  "TIFF",
  "WEBP",
];

const ProfileForm = ({ handleCloseProfileForm, lawyerId, LoginUserData }) => {
  const hasExecuted = useRef(false); // this prevent to render toast twice in useEffect
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const [profileImage, setProfileImage] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState("+1"); // Default country code
  const { token } = useSelector((data) => data.user);
  const [firmName, setFirmName] = useState("");
  const [skipTodayForm, setSkipTodayForm] = useState(false);

  const allFields = [
    "first_name",
    "last_name",
    "birth_date",
    "gender",
    "mobile",
    "address_1",
    "city",
    "state",
    "country",
    "pincode",
  ];
  const {
    control: profileControl,
    handleSubmit: handleProfileSubmit,
    setValue: setProfileValue,
    reset: profileReset,
    watch: profileWatch,
    formState: { errors: profileErrors },
  } = useForm({
    defaultValues: {
      avatar: "",
      first_name: "",
      last_name: "",
      birth_date: null,
      gender: "",
      mobile: "",
      address_1: "",
      city: "",
      state: "",
      country: "",
      pincode: "",
    },
  });

  useEffect(() => {
    if (LoginUserData) {
      // Set form values if data exists
      setProfileValue("first_name", LoginUserData?.first_name || "");
      setProfileValue("last_name", LoginUserData?.last_name || "");
      setProfileValue(
        "birth_date",
        LoginUserData?.birth_date ? dayjs(LoginUserData?.birth_date) : null
      );
      setProfileValue("gender", LoginUserData?.gender || "");
      setProfileValue("mobile", LoginUserData?.mobile || "");
      setProfileValue("address_1", LoginUserData?.address_1 || "");
      setProfileValue("city", LoginUserData?.city || "");
      setProfileValue("state", LoginUserData?.state || "");
      setProfileValue("country", LoginUserData?.country || "");
      setProfileValue("pincode", LoginUserData?.pincode || "");

      // Set profile image if it exists
      if (LoginUserData?.avatar) {
        setProfileImage(`${backend_url}${LoginUserData?.avatar}`);
      }

      // Set country code if mobile number exists
      if (LoginUserData?.mobile && LoginUserData?.mobile?.startsWith("+")) {
        const countryCode = LoginUserData?.mobile.split(" ")[0];
        setSelectedCountry(countryCode);
      }
    }
  }, [setProfileValue, LoginUserData]);

  const handleSkipToday = () => {
    setSkipTodayForm(!skipTodayForm);
  };

  useEffect(() => {
    if (skipTodayForm && !hasExecuted.current) {
      hasExecuted.current = true; // Prevent re-execution

      const futureTime = Date.now() + 24 * 60 * 60 * 1000;
      localStorage.setItem("skipProfilFormToday", futureTime);
      handleCloseProfileForm();
      const date = new Date(futureTime);
      const formattedTime = date.toLocaleString();

      toast.info(`This form will be available again after ${formattedTime}`);
    }
  }, [skipTodayForm, handleCloseProfileForm]);

  useEffect(() => {
    const checkTime = () => {
      const storedTime = localStorage.getItem("skipProfilFormToday");
      if (storedTime && Date.now() >= parseInt(storedTime, 10)) {
        localStorage.removeItem("skipProfilFormToday");
      }
    };

    checkTime();
    const interval = setInterval(checkTime, 60 * 1000);

    return () => clearInterval(interval);
  }, []);

  const handleFileChange = (file) => {
    if (file && FILE_TYPES.includes(file.type.split("/")[1].toUpperCase())) {
      setProfileValue("avatar", file, { shouldValidate: true });
      setProfileImage(URL.createObjectURL(file));
    } else {
      toast.error("Invalid file type! Please upload a valid image file.");
    }
  };

  const handleDeleteImage = () => {
    setProfileImage(null);
    setProfileValue("avatar", "");
  };

  const handleCountryChange = (newCode) => {
    setSelectedCountry(newCode);
  };

  const calculateProgress = (data) => {
    const filledFields = allFields.filter((field) => !!data[field]);
    const progress = Math.round((filledFields.length / allFields.length) * 50); // Max progress is 50%
    localStorage.setItem("profileCompleted", progress);
    return progress;
  };

  const onSubmit = async (data) => {
    // Validate only mandatory fields
    if (!data.first_name || !data.last_name) {
      toast.error("First name and Last name are required to submit the form.");
      return;
    }

    const formData = new FormData();

    if (data.avatar && data.avatar instanceof File) {
      formData.append("avatar", data.avatar);
    }
    formData.append("first_name", data.first_name);
    formData.append("last_name", data.last_name);
    if (data.birth_date) {
      formData.append(
        "birth_date",
        dayjs(data.birth_date).format("YYYY-MM-DD")
      );
    }

    formData.append("gender", data.gender);
    formData.append("mobile", data.mobile);
    formData.append("address_1", data.address_1);
    formData.append("city", data.city);
    formData.append("state", data.state);
    formData.append("country", data.country);
    formData.append("pincode", data.pincode);

    try {
      const response = await axios.put(
        `${backend_api}lawer/update-profile-lawer/${lawyerId}/`,
        formData,
        {
          headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      toast.success("Profile updated successfully");

      // Calculate progress and store it in local storage
      const progress = calculateProgress(data);

      localStorage.setItem("profileNotify", true);
      localStorage.setItem("profileCompleted", progress);
      onFirmSubmit({
        name: `${data.first_name} ${data.last_name}`,
      });
      setFirmName(`Law office of ${data.first_name} ${data.last_name}`);
      setActiveStep(1);
      profileReset();
    } catch (error) {
      toast.error(
        error.response?.data?.message ||
          "Failed to update profile. Please try again."
      );
    }
  };

  const onFirmSubmit = async (data) => {
    try {
      const response = await axios.post(
        `${backend_api}firm/create-law-firm/`,
        data,
        {
          headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response) {
        console.log(response);
        toast.success("Law firm created successfully");
      }
    } catch (error) {
      if (error.response?.data?.firm_name) {
        toast.error("Firm name already exists");
      } else if (error.response?.data?.business_email) {
        toast.error("Business email already registered");
      } else {
        toast.error(
          error.response?.data?.message ||
            "Failed to create firm. Please try again."
        );
      }
    }
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
      }}
    >
      {activeStep === 0 && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              px: "1rem",
              py: "0.5rem",
              bgcolor: "primary.main",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
              <Typography
                variant="body1"
                color="white"
                sx={{ fontSize: "1rem" }}
              >
                Personal Detail
              </Typography>
            </Box>
            <IconButton
              edge="end"
              aria-label="close"
              color="white"
              onClick={handleCloseProfileForm}
            >
              <CloseRounded />
            </IconButton>
          </Box>
          <form onSubmit={handleProfileSubmit(onSubmit)}>
            <Box
              sx={{
                display: "flex",
                px: "1rem",
                mb: "0.5rem",
                textAlign: "center",
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  fontSize: "0.8rem",
                  fontWeight: 500,
                  color: "text.secondary",
                }}
              >
                Fill out this form to proceed—your firm will be created
                automatically upon submission.
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
                p: "0rem 1rem 1rem 1rem",
              }}
            >
              <Box display="flex" gap="1rem">
                {/* Avatar Upload Section */}
                <Controller
                  name="avatar"
                  control={profileControl}
                  render={({ field }) => (
                    <Box sx={{ position: "relative" }}>
                      {profileImage ? (
                        <Box
                          sx={{
                            position: "relative",
                            width: "9.375rem",
                            height: "9.375rem",
                            borderRadius: 2,
                            overflow: "hidden",
                            border: "1px dashed #dadada",
                          }}
                        >
                          <img
                            src={profileImage}
                            alt="Profile"
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                            }}
                          />
                          <Box
                            sx={{
                              position: "absolute",
                              top: 0,
                              left: 0,
                              width: "100%",
                              height: "100%",
                              bgcolor: "rgba(0,0,0,0)",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              transition: "background-color 0.3s",
                              "&:hover": {
                                bgcolor: "rgba(0,0,0,0.5)",
                                ".deletBtn": {
                                  display: "flex",
                                },
                              },
                            }}
                          >
                            <IconButton
                              onClick={handleDeleteImage}
                              className="deletBtn"
                              sx={{
                                display: "none",
                                "&:hover": { color: "red" },
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                              }}
                            >
                              <DeleteRoundedIcon />
                            </IconButton>
                          </Box>
                        </Box>
                      ) : (
                        <FileUploader
                          handleChange={(file) => {
                            handleFileChange(file);
                            field.onChange(file);
                          }}
                          name="avatar"
                          types={FILE_TYPES}
                          children={
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                border: "1px dashed #dadada",
                                p: 2,
                                borderRadius: 2,
                                height: 150,
                                width: 150,
                                textAlign: "center",
                              }}
                            >
                              <AddPhotoAlternateRoundedIcon
                                sx={{ fontSize: "2rem", color: "#6D6E6F" }}
                              />
                              <Typography
                                variant="body2"
                                color={
                                  profileErrors.avatar
                                    ? "error"
                                    : "text.secondary"
                                }
                                sx={{ fontSize: "0.8rem" }}
                              >
                                {profileErrors.avatar
                                  ? "Picture is required "
                                  : "Upload Profile Picture"}
                              </Typography>
                            </Box>
                          }
                        />
                      )}
                    </Box>
                  )}
                />

                {/* Personal Information Fields */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                    width: "100%",
                  }}
                >
                  {/* Name Fields */}
                  <Box sx={{ display: "flex", gap: "1rem" }}>
                    <Controller
                      name="first_name"
                      control={profileControl}
                      rules={{ required: "First name is required" }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label={
                            <span>
                              First Name <span style={{ color: "red" }}>*</span>
                            </span>
                          }
                          size="small"
                          fullWidth
                          error={!!profileErrors.first_name}
                          helperText={profileErrors.first_name?.message}
                        />
                      )}
                    />
                    <Controller
                      name="last_name"
                      control={profileControl}
                      rules={{ required: "Last name is required" }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label={
                            <span>
                              Last Name <span style={{ color: "red" }}>*</span>
                            </span>
                          }
                          size="small"
                          fullWidth
                          error={!!profileErrors.last_name}
                          helperText={profileErrors.last_name?.message}
                        />
                      )}
                    />
                  </Box>

                  {/* Date and Gender Fields */}
                  <Box sx={{ display: "flex", gap: "1rem" }}>
                    <Box sx={{ width: "100%" }}>
                      <Controller
                        name="birth_date"
                        control={profileControl}
                        render={({ field }) => (
                          <DatePicker
                            value={field.value ? dayjs(field.value) : null}
                            onChange={(newValue) => {
                              field.onChange(newValue);
                            }}
                            slotProps={{
                              textField: {
                                label: "Date of Birth",
                                size: "small",
                                fullWidth: true,
                              },
                            }}
                            format="YYYY-MM-DD"
                            maxDate={dayjs()}
                          />
                        )}
                      />
                    </Box>

                    <Controller
                      name="gender"
                      control={profileControl}
                      render={({ field }) => (
                        <FormControl size="small" fullWidth>
                          <InputLabel
                            id="gender-label"
                            sx={{ bgcolor: "#fff", px: "0.3rem" }}
                          >
                            Gender
                          </InputLabel>
                          <Select {...field} labelId="gender-label">
                            {genders?.map((item, i) => (
                              <MenuItem key={i} value={item}>
                                {item}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    />
                  </Box>

                  {/* Mobile Number Field */}
                  <Box display="flex" alignItems="center" gap={1}>
                    <CountrySelect
                      value={selectedCountry}
                      onChange={handleCountryChange}
                    />
                    <Controller
                      name="mobile"
                      control={profileControl}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          size="small"
                          type="number"
                          fullWidth
                          label="Mobile Number"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                {selectedCountry}
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    />
                  </Box>
                </Box>
              </Box>

              {/* Address Fields */}
              <Box sx={{ display: "flex", gap: "1rem" }}>
                <Controller
                  name="address_1"
                  control={profileControl}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      size="small"
                      label="Full Address"
                      placeholder="Enter the full address"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <HomeRoundedIcon sx={{ color: "#DEDEDE" }} />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </Box>

              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}
              >
                <Box sx={{ display: "flex", gap: "1rem" }}>
                  <Controller
                    name="city"
                    control={profileControl}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="City"
                        size="small"
                        fullWidth
                      />
                    )}
                  />
                  <Controller
                    name="state"
                    control={profileControl}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="State"
                        size="small"
                        fullWidth
                      />
                    )}
                  />
                </Box>
                <Box sx={{ display: "flex", gap: "1rem" }}>
                  <Controller
                    name="pincode"
                    control={profileControl}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Pin Code"
                        size="small"
                        fullWidth
                      />
                    )}
                  />
                  <Controller
                    name="country"
                    control={profileControl}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Country"
                        size="small"
                        fullWidth
                      />
                    )}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "1rem",
                }}
              >
                <Box>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="skipToday"
                        checked={skipTodayForm}
                        onChange={handleSkipToday}
                      />
                    }
                    label="Skip for today"
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        fontSize: "0.8rem",
                        fontWeight: 500,
                        color: "text.secondary",
                      },
                    }}
                  />
                </Box>
                <Button variant="contained" type="submit" color="primary">
                  Submit
                </Button>
              </Box>
            </Box>
          </form>
        </Box>
      )}
      {activeStep === 1 && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "1rem",
            py: "2rem",
            px: "1rem",
          }}
        >
          <Typography
            variant="body1"
            sx={{
              fontSize: "1.8rem",
              color: "primary.main",
              fontWeight: "bold",
            }}
          >
            {firmName}
          </Typography>

          <Typography
            variant="body1"
            sx={{
              fontSize: "1.2rem",
              color: "text.secondary",
            }}
          >
            Congratulations on your new firm!
          </Typography>

          <Button
            variant="contained"
            startIcon={<Diversity3Icon />}
            onClick={() => {
              handleCloseProfileForm();
              navigate("/dashboard/team-members");
            }}
            sx={{ mt: 1 }}
          >
            Add Team Members
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default ProfileForm;
