import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  IconButton,
  Slide,
  Tooltip,
  Typography,
  Grid,
  Tab,
  Dialog,
} from "@mui/material";
import TabPanel from "@mui/lab/TabPanel";
import React, { useEffect, useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import RemoveRedEyeRoundedIcon from "@mui/icons-material/RemoveRedEyeRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { calculatePresent, pageScrollStyle } from "../../../styles/styles";
import {
  personalDashBillData,
  personalDashOtherData,
} from "../../../static/staticdata";
import { TabContext, TabList } from "@mui/lab";
import CirProgressBar from "../../CirProgressBar";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import ProgressBarChart from "../../ProgressBarChart";
import LineDataChart from "../../LineDataChart";
import FullscreenRoundedIcon from "@mui/icons-material/FullscreenRounded";
import { AnimatePresence } from "framer-motion";
import { motion } from "framer-motion";
import EmptyBox from "../../../assets/empty-box.webp";
import EmptyList from "../../../assets/empty-todos.webp";
import { useDispatch, useSelector } from "react-redux";
import { getAllTask } from "../../../redux/Apps/Lawyerapp/TaskSlice/TaskSlice";
import { backend_api } from "../../../static/server";
import { useNavigate } from "react-router-dom";
import ProfileForm from "../../../pages/LawyerPages/ProfileForm";
import { getLoginUserData } from "../../../redux/Apps/Lawyerapp/DashboardSlice/DashboardSlice";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const hourlyTabs = [
  { period: "Today", value: 1, target: 2, completed: 1.5 },
  { period: "This Week", value: 2, target: 9.6, completed: 8 },
  { period: "This Month", value: 3, target: 41.7, completed: 0 },
  { period: "This Year", value: 4, target: 500, completed: 0 },
];

const PersonalDash = () => {
  const count = 0;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [value, setValue] = useState(1);
  const [graphFullScreen, setGraphFullScreen] = useState(false);
  const [openProfileForm, setOpenProfileForm] = useState(false);
  const [lawyerId, setLawyerId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { allTask, loading, error } = useSelector((state) => state.task);
  const { token } = useSelector((data) => data.user);
  const { LoginUserData } = useSelector((state) => state.dashboard);

  useEffect(() => {
    dispatch(
      getAllTask({
        url: `${backend_api}task/task-register/`,
        token: token,
      })
    );
    dispatch(
      getLoginUserData({
        url: `${backend_api}lawer/update-profile-lawer/`,
        token: token,
      })
    );
  }, [dispatch, token]);

  const skippedTimestamp = localStorage.getItem("skipProfilFormToday");

  useEffect(() => {
    if (LoginUserData) {
      setLawyerId(LoginUserData?.id);

      const requiredFields = ["first_name", "last_name"];

      const hasMissingFields = requiredFields.some((field) => {
        const value = LoginUserData[field];
        return (
          !value || // Null or undefined
          value === "0" || // Invalid default value
          value.trim?.() === "" // Empty string (after trimming)
        );
      });

      const skipUntil = skippedTimestamp
        ? parseInt(skippedTimestamp, 10)
        : null;
      const currentTime = Date.now();

      if (hasMissingFields && (!skipUntil || currentTime >= skipUntil)) {
        setOpenProfileForm(true);
      }
    }
  }, [LoginUserData, skippedTimestamp]);

  useEffect(() => {
    if (graphFullScreen) {
      setIsLoading(true);

      const timer = setTimeout(() => {
        setIsLoading(false);
      }, 600);
      return () => clearTimeout(timer);
    }
  }, [graphFullScreen]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleCloseProfileForm = () => {
    setOpenProfileForm(false);
  };

  return (
    <>
      <Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: "1rem",
              flexWrap: "wrap",
            }}
          >
            {personalDashOtherData?.map((item, i) => (
              <Box
                key={i}
                sx={{
                  width: "12.5rem",
                  display: "flex",
                  flexDirection: "column",
                  borderRadius: "0.5rem",
                  p: "0.5rem",
                  position: "relative",
                  gap: "1rem",
                  boxShadow: "0px 10px 15px -3px rgba(0,0,0,0.1)",
                  border: "1px solid #e5e5e5",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "1rem",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{ fontSize: "1rem", fontWeight: 500 }}
                  >
                    {item.title}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ fontSize: "1.2rem", fontWeight: 500 }}
                  >
                    {item.DataCout}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    size="small"
                    variant="text"
                    sx={{
                      textTransform: "capitalize",
                      fontSize: "0.7rem",
                      position: "absolute",
                      bottom: "0rem",
                      right: "0rem",
                    }}
                    startIcon={<RemoveRedEyeRoundedIcon />}
                  >
                    View All
                  </Button>
                </Box>
              </Box>
            ))}
          </Box>
          <Box sx={{ display: "flex" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
                width: "50%",
                p: "0.5rem",
              }}
            >
              <Box
                sx={{
                  border: "1px solid #e5e5e5 ",
                  boxShadow: "0px 10px 15px -3px rgba(0,0,0,0.1)",
                  borderRadius: "1rem",
                  overflow: "hidden",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{ fontSize: "1.3rem", fontWeight: 500 }}
                  >
                    {count}
                  </Typography>
                  <Divider orientation="vertical" flexItem />
                  <Box>
                    <Typography
                      variant="body1"
                      sx={{ fontSize: "1.3rem", fontWeight: 500 }}
                    >
                      Monthly Agenda
                    </Typography>
                  </Box>
                  <Divider orientation="vertical" flexItem />
                  <Tooltip title="Add new Agenda">
                    <IconButton aria-label="add_agenda" color="primary">
                      <AddRoundedIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
                <Divider orientation="horizontal" flexItem />
                <Box
                  sx={{
                    height: "15rem",
                    p: "1rem",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "0.5rem",
                    overflow: "auto",
                    ...pageScrollStyle,
                  }}
                >
                  {count > 0 ? (
                    [...Array(count)].map((item, i) => (
                      <Box key={i} sx={{ width: "100%" }}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-evenly",
                            alignItems: "center",
                            gap: "1.5rem",
                          }}
                        >
                          <Box sx={{ display: "flex", gap: "0.2rem" }}>
                            <Typography
                              variant="body1"
                              sx={{ fontSize: "0.8rem" }}
                            >
                              2:00
                            </Typography>
                            <Typography
                              variant="body1"
                              sx={{ fontSize: "0.8rem" }}
                            >
                              -
                            </Typography>
                            <Typography
                              variant="body1"
                              sx={{ fontSize: "0.8rem" }}
                            >
                              2:00
                            </Typography>
                          </Box>
                          <Typography
                            noWrap
                            sx={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              width: "7rem",
                              fontSize: "0.9rem",
                            }}
                          >
                            World tour for Sure
                          </Typography>
                          <Typography
                            variant="body1"
                            sx={{ fontSize: "0.8rem" }}
                          >
                            Nov 7, 2024
                          </Typography>
                          <Box>
                            <Tooltip title={"Look out"}>
                              <IconButton size="small" color="primary">
                                <RemoveRedEyeRoundedIcon />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </Box>
                        <Divider orientation="horizontal" flexItem />
                      </Box>
                    ))
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        gap: "0.3rem",
                        flexDirection: "column",
                      }}
                    >
                      <Box sx={{ height: "9rem" }}>
                        <img
                          src={EmptyBox}
                          alt="Company Logo"
                          style={{
                            height: "100%",
                            width: "100%",
                            objectFit: "contain",
                          }}
                          onError={(e) => {
                            e.onerror = null;
                            e.target.src = require("../../../assets/no-preview.webp");
                          }}
                        />
                      </Box>

                      <Typography variant="body1" color="initial">
                        No Agenda For this Month.
                      </Typography>
                      <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <Button
                          size="small"
                          variant="contained"
                          color="primary"
                          startIcon={<AddRoundedIcon />}
                        >
                          Add Agenda
                        </Button>
                      </Box>
                    </Box>
                  )}
                </Box>
              </Box>
              <Divider orientation="horizontal" flexItem />
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}
              >
                <Typography
                  variant="body1"
                  sx={{ fontSize: "1.3rem", fontWeight: 600 }}
                >
                  Billing Metrics for Firm
                </Typography>
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container spacing={2}>
                    {personalDashBillData.map((item, i) => (
                      <Grid key={i} item xs={6} md={6}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "1rem",
                            boxShadow: "0px 10px 15px -3px rgba(0,0,0,0.1)",
                            borderRadius: "0.5rem",
                            p: "0.5rem",
                            border: "1px solid #e5e5e5",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography
                              variant="body1"
                              sx={{ fontSize: "1.2rem", fontWeight: 500 }}
                            >
                              {item.title}
                            </Typography>
                            <Typography
                              variant="body1"
                              sx={{ fontSize: "1.2rem", fontWeight: 500 }}
                            >
                              {item.num}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: Object.hasOwn(item, "btnText")
                                ? "space-between"
                                : "flex-end",
                            }}
                          >
                            {Object.hasOwn(item, "btnText") ? (
                              <Button
                                variant="contained"
                                size="small"
                                sx={{
                                  textTransform: "capitalize",
                                  fontSize: "0.65rem",
                                }}
                              >
                                Create new bill
                              </Button>
                            ) : null}

                            <Button
                              size="small"
                              variant="text"
                              sx={{
                                textTransform: "capitalize",
                                fontSize: "0.7rem",
                              }}
                              startIcon={<RemoveRedEyeRoundedIcon />}
                            >
                              View All
                            </Button>
                          </Box>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Box>
              <Divider orientation="horizontal" flexItem />
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}
              >
                <Typography
                  variant="body1"
                  sx={{ fontSize: "1.3rem", fontWeight: 600 }}
                >
                  Financial Metrics for user Name
                </Typography>
                <Box
                  sx={{
                    border: "1px solid #e5e5e5",
                    p: "0.5rem",
                    borderRadius: "1rem",
                    boxShadow: " 0px 10px 15px -3px rgba(0,0,0,0.1)",
                    display: "flex",
                    flexDirection: "column",
                    // justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <TabContext value={value}>
                    <Box>
                      <TabList onChange={handleChange}>
                        {hourlyTabs.map((item, i) => (
                          <Tab
                            key={i}
                            sx={{
                              textTransform: "capitalize",
                              p: "0rem",
                              m: "0rem",
                            }}
                            label={item.period}
                            value={item.value}
                          />
                        ))}
                      </TabList>
                    </Box>

                    {hourlyTabs.map((item, i) => (
                      <TabPanel
                        key={i}
                        sx={{ p: "0rem" }}
                        value={
                          item.value !== undefined
                            ? item.value
                            : i === 0
                            ? 1
                            : item.value
                        }
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <ProgressBarChart />
                        </Box>
                      </TabPanel>
                    ))}
                  </TabContext>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                width: "50%",
                p: "0.5rem",
                display: "flex",
                gap: "1rem",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  border: "1px solid #e5e5e5 ",
                  boxShadow: "0px 10px 15px -3px rgba(0,0,0,0.1)",
                  borderRadius: "1rem",
                  position: "relative",
                  overflow: "hidden",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{ fontSize: "1.3rem", fontWeight: 500 }}
                  >
                    {allTask && allTask?.length > 0 ? allTask?.length : 0}
                  </Typography>
                  <Divider orientation="vertical" flexItem />
                  <Box>
                    <Typography
                      variant="body1"
                      sx={{ fontSize: "1.3rem", fontWeight: 500 }}
                    >
                      Tasks Due Today
                    </Typography>
                  </Box>
                  <Divider orientation="vertical" flexItem />
                  <Tooltip title="Add new task">
                    <IconButton color="primary">
                      <AddRoundedIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
                <Divider orientation="horizontal" flexItem />
                <Box
                  sx={{
                    ...pageScrollStyle,
                    height: "15rem",
                    p: "1rem",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "0.1rem",
                    overflow: "auto",
                  }}
                >
                  {allTask && allTask?.length > 0 ? (
                    allTask?.map((item, i) => (
                      <Box key={i} sx={{ width: "100%" }}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            gap: "0.5rem",
                          }}
                        >
                          <FormControlLabel
                            control={<Checkbox />}
                            label={
                              <Typography
                                noWrap
                                sx={{
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                  width: "7rem",
                                  fontSize: "0.9rem",
                                }}
                              >
                                {item.task_name}
                              </Typography>
                            }
                            value="i am check box of personal dash"
                            onChange={(e) => {
                              console.log(e.target.value);
                            }}
                          />
                          <Typography
                            variant="body1"
                            sx={{
                              fontSize: "0.8rem",
                              borderRadius: "1rem",
                              px: "0.5rem",
                              color: "#fff",
                              bgcolor:
                                item.priority === "high"
                                  ? "#D32F2F"
                                  : item.priority === "low"
                                  ? "#FFC107"
                                  : "#004242",
                            }}
                          >
                            {item.priority}
                          </Typography>
                          <Box sx={{ display: "flex", gap: "0.2rem" }}>
                            <Typography
                              variant="body1"
                              sx={{ fontSize: "0.8rem" }}
                            >
                              {item.due_date}
                            </Typography>
                          </Box>
                        </Box>
                        <Divider orientation="horizontal" flexItem />
                      </Box>
                    ))
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        gap: "0.3rem",
                        flexDirection: "column",
                      }}
                    >
                      <Box sx={{ height: "9rem" }}>
                        <img
                          src={EmptyList}
                          alt="Company Logo"
                          style={{
                            height: "100%",
                            width: "100%",
                            objectFit: "contain",
                          }}
                          onError={(e) => {
                            e.onerror = null;
                            e.target.src = require("../../../assets/no-preview.webp");
                          }}
                        />
                      </Box>
                      <Typography variant="body1" color="initial">
                        No task for today.
                      </Typography>
                    </Box>
                  )}

                  <Box
                    sx={{
                      position: "absolute",
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      left: "0rem",
                      bottom: "0rem",
                      width: "100%",
                      height: "3rem",
                      background: " rgba( 255, 255, 255, 0.5 )",
                      backdropFilter: " blur( 4px )",
                      webkitBackdropFilter: " blur( 4px )",
                      pr: "1rem",
                      borderRadius: "0.5rem",
                    }}
                  >
                    {allTask?.length > 0 ? (
                      <Button
                        variant="text"
                        sx={{ fontSize: "0.9rem", textTransform: "capitalize" }}
                        startIcon={<RemoveRedEyeRoundedIcon />}
                        onClick={() => navigate("/dashboard/tasks")}
                      >
                        View All
                      </Button>
                    ) : (
                      <Button
                        size="small"
                        variant="contained"
                        color="primary"
                        startIcon={<AddRoundedIcon />}
                      >
                        Add task
                      </Button>
                    )}
                  </Box>
                </Box>
              </Box>
              <Divider orientation="horizontal" flexItem />
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}
              >
                <Typography
                  variant="body1"
                  sx={{ fontSize: "1.3rem", fontWeight: 600 }}
                >
                  Hourly Metrics for user name.
                </Typography>
                <Box
                  sx={{
                    border: "1px solid #e5e5e5",
                    p: "0.5rem",
                    borderRadius: "1rem",
                    boxShadow: " 0px 10px 15px -3px rgba(0,0,0,0.1)",
                    display: "flex",
                    flexDirection: "column",
                    // justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{ fontSize: "1.1rem", fontWeight: 500 }}
                  >
                    Billable Hours Target
                  </Typography>
                  <Box>
                    <TabContext value={value}>
                      <Box>
                        <TabList onChange={handleChange}>
                          {hourlyTabs.map((item, i) => (
                            <Tab
                              key={i}
                              sx={{
                                textTransform: "capitalize",
                                p: "0rem",
                                m: "0rem",
                              }}
                              label={item.period}
                              value={item.value}
                            />
                          ))}
                        </TabList>
                      </Box>

                      {hourlyTabs.map((item, i) => (
                        <TabPanel
                          key={i}
                          sx={{ p: "0rem" }}
                          value={
                            item.value !== undefined
                              ? item.value
                              : i === 0
                              ? 1
                              : item.value
                          }
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <CirProgressBar
                              series={calculatePresent(
                                item.target,
                                item.completed
                              )}
                              completed={item.completed}
                              target={item.target}
                              color="#4CAF50"
                              fontSize="1.2rem"
                              labelColor="#3957db"
                              labelFontSize="1.2rem"
                              backgroundColor="#fff"
                            />
                          </Box>
                        </TabPanel>
                      ))}
                    </TabContext>
                    <Box>
                      <Button
                        variant="text"
                        sx={{
                          textTransform: "capitalize",
                          fontSize: "0.9rem",
                          textDecoration: "underline",
                        }}
                        startIcon={<SettingsRoundedIcon />}
                      >
                        Personal performance settings
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Divider orientation="horizontal" flexItem />
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{ fontSize: "1.3rem", fontWeight: 600 }}
                  >
                    Detailed Annual Report
                  </Typography>
                  <Tooltip title="Full Screen">
                    <IconButton onClick={() => setGraphFullScreen(true)}>
                      <FullscreenRoundedIcon sx={{ fontSize: "1.2rem" }} />
                    </IconButton>
                  </Tooltip>
                </Box>

                <AnimatePresence mode="wait">
                  {!graphFullScreen ? (
                    // Normal view
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.3 }}
                      layoutId="chart-container"
                    >
                      <Box
                        sx={{
                          border: "1px solid #e5e5e5",
                          p: "0.5rem",
                          borderRadius: "1rem",
                          boxShadow: "0px 10px 15px -3px rgba(0,0,0,0.1)",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          height: "15.5rem",
                        }}
                      >
                        <LineDataChart />
                      </Box>
                    </motion.div>
                  ) : (
                    // Full screen overlay
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.3 }}
                      style={{
                        position: "fixed",
                        top: 0,
                        left: 0,
                        width: "100vw",
                        height: "100vh",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        zIndex: 1000,
                      }}
                    >
                      {/* Content container */}
                      <motion.div
                        layoutId="chart-container"
                        style={{
                          backgroundColor: "#fff",
                          borderRadius: "0.5rem",
                          boxShadow: "0 4px 10px rgba(0, 0, 0, 0.3)",
                          width: "70%",
                          height: "80%",
                          position: "relative",
                          overflow: "hidden",
                        }}
                        transition={{
                          type: "spring",
                          stiffness: 300,
                          damping: 30,
                          duration: 0.5,
                        }}
                      >
                        <Box sx={{ height: "100%", p: "1.2rem" }}>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              mb: 2,
                            }}
                          >
                            <Typography
                              variant="body1"
                              sx={{ fontSize: "1.3rem", fontWeight: 600 }}
                            >
                              Detailed Annual Report
                            </Typography>
                            <IconButton
                              onClick={() => setGraphFullScreen(false)}
                              className="hover:bg-gray-100 transition-colors duration-200"
                              sx={{ color: "text.primary" }}
                            >
                              <CloseRoundedIcon />
                            </IconButton>
                          </Box>

                          <Box
                            sx={{
                              position: "relative",
                              height: "calc(100% - 60px)",
                            }}
                          >
                            {isLoading ? (
                              <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                className="absolute inset-0 flex items-center justify-center bg-white"
                              >
                                <div className="w-12 h-12 border-4 border-gray-200 border-t-blue-500 rounded-full animate-spin" />
                              </motion.div>
                            ) : (
                              <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ duration: 0.3, delay: 0.2 }}
                                style={{ height: "100%" }}
                              >
                                <LineDataChart />
                              </motion.div>
                            )}
                          </Box>
                        </Box>
                      </motion.div>
                    </motion.div>
                  )}
                </AnimatePresence>
              </Box>
            </Box>
          </Box>
        </Box>

        <Dialog
          open={openProfileForm}
          TransitionComponent={Transition}
          onClose={handleCloseProfileForm}
          BackdropProps={{
            style: {
              backdropFilter: "blur(5px)",
              backgroundColor: "rgba(0, 0, 0, 0.4)",
            },
          }}
          PaperProps={{
            sx: {
              width: "35rem",
              p: "0rem",
              borderRadius: "0.5rem",
            },
          }}
          sx={{
            "& .MuiDialog-paper": { ...pageScrollStyle },
          }}
        >
          <ProfileForm
            handleCloseProfileForm={handleCloseProfileForm}
            lawyerId={lawyerId}
            LoginUserData={LoginUserData}
          />
        </Dialog>
      </Box>
    </>
  );
};

export default PersonalDash;
