import React, { useEffect, useState, useMemo } from "react";
import {
  Route,
  Routes,
  Navigate,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { routes } from "../Routes";
import SuspanceLoading from "./SuspanceLoading";
import SignupPage from "../pages/SignupPage";
import HomePage from "../pages/LandingPages/HomePage";
import ResetPassForm from "./Auth/ResetPassForm";
import NewUserPage from "../pages/LawyerPages/TeamMembers/NewUserPage";
import DashboardPage from "../pages/LawyerPages/DashboardPage";

const ProtectedRoutes = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const isAuthenticated = localStorage.getItem("persist:root");
  const userId = localStorage.getItem("UserId");

  useEffect(() => {
    if (!isAuthenticated || !userId) {
      const publicPaths = [
        "/",
        "/login",
        "/request-forgot-password",
        "/request-forgot-password/verification-code",
        "/new-user",
      ];
      if (!publicPaths.includes(location.pathname)) {
        navigate("/login");
      }
    }
    setLoading(false);
  }, [navigate, isAuthenticated, userId, location]);

  const allValidPaths = useMemo(() => {
    return routes.map((route) => route.path);
  }, []);

 
  const isValidPath = useMemo(() => {
    return (
      allValidPaths.includes(location.pathname) ||
      [
        "/",
        "/login",
        "/dashboard",
        "/request-forgot-password",
        "/request-forgot-password/verification-code",
        "/new-user",
      ].includes(location.pathname)
    );
  }, [location.pathname, allValidPaths]);

  const processedRoutes = useMemo(() => {
    return routes
      ?.filter((route) => !route.protected || isAuthenticated)
      ?.map((route, index) => (
        <Route key={index} path={route.path} element={route.element} />
      ));
  }, [isAuthenticated]);

  if (loading) {
    return <SuspanceLoading />;
  }

  if (!isValidPath) {
    if (isAuthenticated && userId) {
      return <Navigate to="/dashboard" replace />;
    } else {
      return <Navigate to="/" replace />;
    }
  }

  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/login" element={<SignupPage />} />
      <Route
        path="/request-forgot-password/verification-code"
        element={<ResetPassForm />}
      />
      <Route path="/new-user" element={<NewUserPage />} />

      <Route
        path="/dashboard"
        element={
          isAuthenticated ? <DashboardPage /> : <Navigate to="/login" replace />
        }
      />

      {processedRoutes}
     
      <Route
        path="*"
        element={
          isAuthenticated && userId ? (
            <Navigate to="/dashboard" replace />
          ) : (
            <Navigate to="/" replace />
          )
        }
      />
    </Routes>
  );
};

export default ProtectedRoutes;
