import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

const CirProgressBar = ({
  series,
  completed,
  target,
  color,
  fontSize,
  labelColor,
  labelFontSize,
  backgroundColor,
}) => {
  const [animatedSeries, setAnimatedSeries] = useState(0);

  useEffect(() => {
    // Start animation from 0 to target value
    setAnimatedSeries(0);
    setTimeout(() => {
      setAnimatedSeries(series);
    }, 100);
  }, [series]);

  const options = {
    chart: {
      height: "100%",
      type: "radialBar",
      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 800,
        animateGradually: {
          enabled: true,
          delay: 150
        },
        dynamicAnimation: {
          enabled: true,
          speed: 800
        }
      }
    },
    plotOptions: {
      radialBar: {
        startAngle: 0, // Changed from -225 to -90 to start at top
        endAngle: 360,   // Changed from 135 to 270 to complete at top
        hollow: {
          size: "65%",
          background: backgroundColor,
          dropShadow: {
            enabled: true,
            top: -3,
            blur: 5,
            opacity: 0
          }
        },
        track: {
          background: backgroundColor,
          strokeWidth: "67%",
          dropShadow: {
            enabled: true,
            top: 0,
            blur: 5,
            opacity: 0.35
          }
        },
        dataLabels: {
          show: true,
          name: {
            offsetY: -10,
            show: true,
            color: labelColor,
            fontSize: labelFontSize
          },
          value: {
            formatter: (val) => `${val}%`,
            color: "#383d5e",
            fontSize: fontSize,
            show: true
          },
          total: {
            show: true,
            label: `${completed} Hours `,
            fontSize: labelFontSize,
            fontWeight: "100",
            color: labelColor,
            formatter: () => `${target} Hours`
          }
        }
      }
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        type: "vertical",
        shadeIntensity: 0,
        gradientToColors: [color],
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100]
      }
    },
    stroke: {
      lineCap: "round"
    }
  };

  return (
    <Box sx={{ p: "0rem" }}>
      <ReactApexChart
        options={options}
        series={[animatedSeries]}
        type="radialBar"
      />
    </Box>
  );
};

export default CirProgressBar;