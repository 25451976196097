import React, { useEffect, useState } from "react";
import {
  Box,
  Menu,
  MenuItem,
  ListItemIcon,
  Divider,
  IconButton,
  Typography,
  Tooltip,
  InputBase,
  Paper,
  Drawer,
  Avatar,
  CircularProgress,
  Button,
} from "@mui/material";
import { FaSignOutAlt } from "react-icons/fa";
import NotificationsActiveRoundedIcon from "@mui/icons-material/NotificationsActiveRounded";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../../redux/Apps/users/userSlice";
import {
  createNewOpenFormName,
  profileCompleted,
} from "../../../static/staticdata";
import UserAvatar from "../../UserAvatar";
import { CloseRounded, PersonRounded } from "@mui/icons-material";
import ProfileCircularProgress from "../../ProfileCircularProgress";
import { getLoginUserData } from "../../../redux/Apps/Lawyerapp/DashboardSlice/DashboardSlice";
import { backend_api } from "../../../static/server";
import { pageScrollStyle } from "../../../styles/styles";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { useDialog } from "../../DialogContext";
import Stopwatch from "./Stopwatch";
import InviteUserForm from "../TeamMember/InviteUserForm";
import companyLogo from "../../../assets/nav-lexmom.webp"
const Navbar = () => {
  const { showDialog } = useDialog();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [filteredItems, setFilteredItems] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searching, setSearching] = useState(false);
  const [searchBox, setSearchBox] = useState(false);
  const [createNewMenu, setCreateNewMenu] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [userData, setUserData] = useState(null);
  const [notifyDrawer, setNotifyDrawer] = useState(false);
  const profileNotify = localStorage.getItem("profileNotify");
  const open = Boolean(anchorEl);
  const { token } = useSelector((data) => data.user);
  const { LoginUserData, loading } = useSelector((state) => state.dashboard);

  const handleOpenFormDialog = (e, value) => {
    e.preventDefault();
    setCreateNewMenu(null);
    if (value === "add_team_member") {
      showDialog(<InviteUserForm />, {
        title: "Invite Team Member",
      });
    } else if (value === "add_matter") {
      navigate("/dashboard/matter", { replace: true });

      setTimeout(() => {
        sessionStorage.setItem("dialogCase", "true");
      }, 100);
    } else if (value === "add_contact") {
      navigate("dashboard/contact-details", { replace: true });

      setTimeout(() => {
        sessionStorage.setItem("dialogOpen", "true");
      }, 100);
    }
  };

  useEffect(() => {
    dispatch(
      getLoginUserData({
        url: `${backend_api}lawer/update-profile-lawer/`,
        token: token,
      })
    );
  }, [dispatch, token]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleNotifyDrawer = (newOpen) => () => {
    setNotifyDrawer(newOpen);
  };

  const items = ["Apple", "Banana", "Orange", "Mango", "Grapes", "Pineapple"];

  const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);

    if (term.trim()) {
      setFilteredItems(
        items.filter((item) => item.toLowerCase().includes(term.toLowerCase()))
      );
      setSearching(true);
    } else {
      setSearching(false);
      setFilteredItems([]);
    }
  };

  const handleLogout = () => {
    navigate("/login");
    handleClose();
    dispatch(logout());
    localStorage.clear();
    sessionStorage.clear();
    localStorage.removeItem("persist:root");
  };

  useEffect(() => {
    if (LoginUserData) setUserData(LoginUserData);
  }, [LoginUserData]);

  useEffect(() => {
    const allFields = [
      "first_name",
      "last_name",
      "birth_date",
      "gender",
      "mobile",
      "address_1",
      "city",
      "state",
      "country",
      "pincode",
    ];
    if (LoginUserData) {
      const hasEmptyFields = allFields.some((field) => {
        const value = LoginUserData[field];
        return (
          value === undefined ||
          value === null ||
          value === "" ||
          (field === "birth_date" && !value) ||
          (typeof value === "string" && value.trim() === "")
        );
      });

      if (hasEmptyFields) {
        const filledFields = allFields.filter((field) => {
          const value = LoginUserData[field];
          return (
            value !== undefined &&
            value !== null &&
            value !== "" &&
            (field !== "birth_date" || value) &&
            (typeof value !== "string" || value.trim() !== "")
          );
        });

        const progress = Math.round(
          (filledFields.length / allFields.length) * 50
        );

        localStorage.setItem("profileNotify", "true");
        localStorage.setItem("profileCompleted", progress.toString());
      }
    }
  }, [LoginUserData]);

  return (
    <Box
      sx={{
        width: "100%",
        bgcolor: "primary.main",
        px: "1rem",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box sx={{ height: "2rem" }}>
            <img
              src={companyLogo}
              alt="Company Logo"
              style={{
                height: "100%",
                width: "100%",
                objectFit: "contain",
                filter: "brightness(0) invert(1)",
              }}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = require("../../../assets/nav-lexmom.webp");
              }}
            />
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "0.5rem",
          }}
        >
          <Box sx={{ position: "relative" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                bgcolor: "#fff",
                borderRadius: "5rem",
                border: "1px solid #ccc",
                height: "2.6rem",
                width: searchBox ? "20rem" : "2.6rem",
                transition: "all 0.2s",
                px: "0.5rem",
                py: "0.3rem",
              }}
            >
              {searchBox && (
                <InputBase
                  fullWidth
                  placeholder={`Search law office of ${LoginUserData?.first_name} ${LoginUserData?.last_name}`}
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
              )}
              <SearchRoundedIcon
                sx={{ color: "text.secodary", cursor: "pointer" }}
                onClick={() => setSearchBox(!searchBox)}
              />
            </Box>

            {searching && (
              <Paper
                sx={{
                  position: "absolute",
                  top: "100%",
                  left: 0,
                  right: 0,
                  mt: "1rem",
                  maxHeight: 200,
                  overflowY: "auto",
                  borderRadius: "0.5rem",
                  boxShadow: 3,
                  zIndex: 9,
                  ...pageScrollStyle,
                }}
              >
                {filteredItems?.length > 0 ? (
                  filteredItems?.map((item, index) => (
                    <Box
                      key={index}
                      sx={{
                        px: "1rem",
                        py: "0.5rem",
                        cursor: "pointer",
                        "&:hover": { bgcolor: "#f5f5f5" },
                      }}
                      onClick={() => {
                        setSearchTerm(item);
                        setSearching(false);
                      }}
                    >
                      <Typography>{item}</Typography>
                    </Box>
                  ))
                ) : (
                  <Box sx={{ px: 2, py: 1 }}>
                    <Typography color="text.secondary">
                      No results found
                    </Typography>
                  </Box>
                )}
              </Paper>
            )}
          </Box>
          <Box sx={{ position: "relative" }}>
            <Stopwatch />
          </Box>
          {/* Notification Bell */}
          <Tooltip title="Notifications">
            <Box sx={{ position: "relative" }}>
              <IconButton
                color="white"
                onClick={toggleNotifyDrawer(true)}
                sx={{
                  animation: profileNotify
                    ? "shake 0.8s cubic-bezier(0.36, 0.07, 0.19, 0.97) both"
                    : "none",
                  "@keyframes shake": {
                    "10%, 90%": { transform: "translate3d(-1px, 0, 0)" },
                    "20%, 80%": { transform: "translate3d(2px, 0, 0)" },
                    "30%, 50%, 70%": { transform: "translate3d(-4px, 0, 0)" },
                    "40%, 60%": { transform: "translate3d(4px, 0, 0)" },
                  },
                }}
              >
                <NotificationsActiveRoundedIcon sx={{ fontSize: "1.6rem" }} />
              </IconButton>
              {profileNotify && (
                <Box
                  sx={{
                    position: "absolute",
                    top: 4,
                    right: 4,
                    width: "10px",
                    height: "10px",
                    bgcolor: "red",
                    borderRadius: "50%",
                  }}
                />
              )}
            </Box>
          </Tooltip>
          <Tooltip title="Create New">
            <IconButton
              color="white"
              onClick={(e) => setCreateNewMenu(e.currentTarget)}
            >
              <AddRoundedIcon sx={{ fontSize: "1.6rem" }} />
            </IconButton>
          </Tooltip>
          <Menu
            anchorEl={createNewMenu}
            open={Boolean(createNewMenu)}
            onClose={() => setCreateNewMenu(null)}
            PaperProps={{
              elevation: 4,
              sx: {
                mt: "1rem",
                minWidth: 200,
              },
            }}
          >
            {createNewOpenFormName?.map((forms, i) => (
              <MenuItem
                key={i}
                onClick={(e) => {
                  setCreateNewMenu(null);
                  console.log(forms?.value);
                  handleOpenFormDialog(e, forms?.value);
                }}
              >
                {forms?.label}
              </MenuItem>
            ))}
          </Menu>

          <Tooltip title="Account settings">
            <IconButton onClick={handleClick}>
              <Box sx={{ height: "2.2rem", width: "2.2rem" }}>
                {loading ? (
                  <CircularProgress color="white" size={20} />
                ) : (
                  <UserAvatar
                    firstName={LoginUserData?.first_name}
                    imgSrc={LoginUserData?.avatar}
                    height={"2.2rem"}
                    width={"2.2rem"}
                  />
                )}
              </Box>
            </IconButton>
          </Tooltip>
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            PaperProps={{
              elevation: 4,
              sx: {
                mt: "1rem",
                minWidth: 200,
              },
            }}
          >
            <MenuItem
              onClick={() => {
                handleClose();
                navigate("/dashboard/profile");
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", gap: "1rem" }}>
                <UserAvatar
                  firstName={LoginUserData?.first_name}
                  imgSrc={LoginUserData?.avatar}
                  height={"2.2rem"}
                  width={"2.2rem"}
                />
                <Typography
                  variant="body1"
                  sx={{ fontSize: "1rem", fontWeight: 500 }}
                >
                  Profile
                </Typography>
              </Box>
            </MenuItem>
            <Divider />
            <MenuItem onClick={handleLogout}>
              <ListItemIcon>
                <FaSignOutAlt />
              </ListItemIcon>
              <Typography variant="body1">Logout</Typography>
            </MenuItem>
          </Menu>
        </Box>
      </Box>

      <Drawer
        anchor="right"
        open={notifyDrawer}
        onClose={toggleNotifyDrawer(false)}
        sx={{
          "& .MuiDrawer-paper": {
            width: "360px",
            p: "1rem",
            borderRadius: "8px 0 0 8px",
          },
        }}
      >
        {/* Header */}
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb="1rem"
        >
          <Typography variant="h6" fontWeight="600">
            Notifications
          </Typography>
          <IconButton onClick={toggleNotifyDrawer(false)}>
            <CloseRounded />
          </IconButton>
        </Box>

        {profileNotify ? (
          <Box
            sx={{
              borderRadius: "12px",
              p: "1rem",
              mb: "1rem",
              display: "flex",
              alignItems: "center",
              gap: "1rem",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.4)",
              cursor: "pointer",
            }}
            onClick={() => navigate("/dashboard/profile")}
          >
            <Avatar
              sx={{
                backgroundColor: "#4f46e5",
                width: "48px",
                height: "48px",
              }}
            >
              <PersonRounded />
            </Avatar>
            <Box>
              <Typography fontWeight="500" fontSize="1rem">
                Complete your Profile
              </Typography>
              <Typography fontSize="0.875rem" color="#9ca3af">
                for better experience
              </Typography>
            </Box>
            <Box>
              <ProfileCircularProgress value={profileCompleted} />
            </Box>
          </Box>
        ) : (
          <Typography textAlign="center" fontSize="0.875rem" color="#9ca3af">
            No more notifications
          </Typography>
        )}
      </Drawer>
    </Box>
  );
};

export default Navbar;
