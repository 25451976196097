import React, { useCallback, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import {
  TextField,
  Typography,
  InputAdornment,
  IconButton,
  Box,
  Dialog,
  DialogTitle,
  Button,
  DialogContent,
  styled,
} from "@mui/material";
import { motion } from "framer-motion";
import axios from "axios";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { VisibilityOffRounded, VisibilityRounded } from "@mui/icons-material";
import LoginIcon from "@mui/icons-material/Login";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import { LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";
import SendRoundedIcon from "@mui/icons-material/SendRounded";
import { CloseRounded } from "@mui/icons-material";
import PropTypes from "prop-types";
import { Input as BaseInput } from "@mui/base/Input";
import { debounce } from "../styles/styles";
import { backend_api } from "../static/server";
import { setLawerId, setToken, setUserId } from "../redux/Apps/users/userSlice";
import companyLogo from "../assets/nav-lexmom.webp"
import LawyerGroupImg from "../assets/lawyer-group.webp"
import emailSend from "../assets/email_sent.webp"
import RetroGridBg from "../components/RetroGridBg";
// import AccountBalanceRoundedIcon from "@mui/icons-material/AccountBalanceRounded";
// import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
// import MarkEmailReadRoundedIcon from "@mui/icons-material/MarkEmailReadRounded";

const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

function OTP({ separator, length, value, onChange }) {
  const inputRefs = useRef(new Array(length).fill(null));

  const focusInput = (targetIndex) => {
    const targetInput = inputRefs.current[targetIndex];
    targetInput.focus();
  };

  const selectInput = (targetIndex) => {
    const targetInput = inputRefs.current[targetIndex];
    targetInput.select();
  };

  const handleKeyDown = (event, currentIndex) => {
    switch (event.key) {
      case "ArrowUp":
      case "ArrowDown":
      case " ":
        event.preventDefault();
        break;
      case "ArrowLeft":
        event.preventDefault();
        if (currentIndex > 0) {
          focusInput(currentIndex - 1);
          selectInput(currentIndex - 1);
        }
        break;
      case "ArrowRight":
        event.preventDefault();
        if (currentIndex < length - 1) {
          focusInput(currentIndex + 1);
          selectInput(currentIndex + 1);
        }
        break;
      case "Delete":
        event.preventDefault();
        onChange((prevOtp) => {
          const otp =
            prevOtp.slice(0, currentIndex) + prevOtp.slice(currentIndex + 1);
          return otp;
        });

        break;
      case "Backspace":
        event.preventDefault();
        if (currentIndex > 0) {
          focusInput(currentIndex - 1);
          selectInput(currentIndex - 1);
        }

        onChange((prevOtp) => {
          const otp =
            prevOtp.slice(0, currentIndex) + prevOtp.slice(currentIndex + 1);
          return otp;
        });
        break;

      default:
        break;
    }
  };

  const handleChange = (event, currentIndex) => {
    const currentValue = event.target.value;
    let indexToEnter = 0;

    while (indexToEnter <= currentIndex) {
      if (
        inputRefs.current[indexToEnter].value &&
        indexToEnter < currentIndex
      ) {
        indexToEnter += 1;
      } else {
        break;
      }
    }
    onChange((prev) => {
      const otpArray = prev.split("");
      const lastValue = currentValue[currentValue.length - 1];
      otpArray[indexToEnter] = lastValue;
      return otpArray.join("");
    });
    if (currentValue !== "") {
      if (currentIndex < length - 1) {
        focusInput(currentIndex + 1);
      }
    }
  };

  const handleClick = (event, currentIndex) => {
    selectInput(currentIndex);
  };

  const handlePaste = (event, currentIndex) => {
    event.preventDefault();
    const clipboardData = event.clipboardData;

    // Check if there is text data in the clipboard
    if (clipboardData.types.includes("text/plain")) {
      let pastedText = clipboardData.getData("text/plain");
      pastedText = pastedText.substring(0, length).trim();
      let indexToEnter = 0;

      while (indexToEnter <= currentIndex) {
        if (
          inputRefs.current[indexToEnter].value &&
          indexToEnter < currentIndex
        ) {
          indexToEnter += 1;
        } else {
          break;
        }
      }

      const otpArray = value.split("");

      for (let i = indexToEnter; i < length; i += 1) {
        const lastValue = pastedText[i - indexToEnter] ?? " ";
        otpArray[i] = lastValue;
      }

      onChange(otpArray.join(""));
    }
  };

  return (
    <Box sx={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
      {new Array(length).fill(null).map((_, index) => (
        <React.Fragment key={index}>
          <BaseInput
            slots={{
              input: InputElement,
            }}
            aria-label={`Digit ${index + 1} of OTP`}
            slotProps={{
              input: {
                ref: (ele) => {
                  inputRefs.current[index] = ele;
                },
                onKeyDown: (event) => handleKeyDown(event, index),
                onChange: (event) => handleChange(event, index),
                onClick: (event) => handleClick(event, index),
                onPaste: (event) => handlePaste(event, index),
                value: value[index] ?? "",
              },
            }}
          />
          {index === length - 1 ? null : (
            <Typography
              variant="body1"
              component="span"
              color="text.secondary"
              sx={{ fontSize: "0.8rem", fontWeight: 500 }}
            >
              {separator}
            </Typography>
          )}
        </React.Fragment>
      ))}
    </Box>
  );
}

OTP.propTypes = {
  length: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  separator: PropTypes.node,
  value: PropTypes.string.isRequired,
};

// const RoleCard = ({ title, description, icon, color, onClick, selected }) => (
//   <motion.div
//     whileHover={{ scale: 1.05 }}
//     className={`bg-white rounded-xl p-6 w-72 cursor-pointer shadow-lg hover:shadow-xl transition-shadow ${
//       selected ? "ring-2 ring-[#00B3B0]" : ""
//     }`}
//     onClick={onClick}
//   >
//     <div className="flex flex-col gap-4">
//       <Box
//         sx={{
//           display: "flex",
//           justifyContent: "start",
//           alignItems: "center",
//           position: "relative",
//         }}
//       >
//         <Typography
//           variant="body1"
//           sx={{
//             opacity: "0.1",
//             color: color,
//             position: "absolute",
//             left: "0rem",
//           }}
//         >
//           {icon}
//         </Typography>
//         <Typography
//           variant="h5"
//           className="font-semibold"
//           sx={{ zIndex: 1, color: color, ml: "3rem" }}
//         >
//           {title}
//         </Typography>
//       </Box>
//       <Typography
//         variant="body2"
//         color="text.secondary"
//         sx={{ fontSize: "0.8rem", mt: 1 }}
//       >
//         {description}
//       </Typography>
//     </div>
//   </motion.div>
// );

// const roles = [
//   {
//     title: "Lawyer",
//     value: "lawyer",
//     description:
//       "Oversee case management, interact with clients, and provide expert legal advice. Streamline your workflow with advanced tools.",
//     icon: <AccountBalanceRoundedIcon sx={{ fontSize: "5rem" }} />,
//   },
//   {
//     title: "Client",
//     value: "client",
//     description:
//       "Track your cases, communicate with lawyers, and access legal documents securely.",
//     icon: <PersonRoundedIcon sx={{ fontSize: "5rem" }} />,
//   },
// ];
const SignupPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showOtpInput, setShowOtpInput] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [encryptedKey, setEncryptedKey] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isOtpLoading, setIsOtpLoading] = useState(false);
  const [openForgotPass, setOpenForgotPass] = useState(false);
  const [isEmailSent, setIsEmailSent] = useState(false);
  // const [openSignupType, setOpenSignUpType] = useState(false);
  // const [userType, setUserType] = useState("");
  const [userExist, setUserExist] = useState(null);
  const [passwordError, setPasswordError] = useState("");
  const [otp, setOtp] = useState("");

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    reset,
    trigger,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
      confirmPassword: "",
      otp: "",
      user_type: "lawyer",
    },
  });

  // useEffect(() => {
  //   if (!showOtpInput && !encryptedKey && !userType) {
  //     setOpenSignUpType(true);
  //   }
  // }, []);

  // useEffect(() => {
  //   setValue("user_type", userType);
  // }, [userType, setValue]);

  const emailValue = watch("email");
  const password = watch("password");
  const confirmPassword = watch("confirmPassword");

  useEffect(() => {
    if (userExist === false && password && confirmPassword) {
      if (password !== confirmPassword) {
        setPasswordError("Passwords do not match");
      } else {
        setPasswordError("");
      }
    }
  }, [password, confirmPassword, userExist]);

  const handleCheckEmail = useCallback(
    debounce(async (email) => {
      if (!email || !EMAIL_REGEX.test(email)) {
        setUserExist(null);
        return;
      }

      setIsLoading(true);
      try {
        const response = await axios.get(
          `${backend_api}user/check-mail/?email=${email}`,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        );
        setUserExist(response?.data?.exist);
      } catch (error) {
        console.error("Error checking email:", error);
        toast.error("Error checking email availability");
      } finally {
        setIsLoading(false);
      }
    }, 300),
    []
  );

  useEffect(() => {
    if (emailValue) {
      handleCheckEmail(emailValue);
    }
  }, [emailValue, handleCheckEmail]);

  const {
    register: forgotRegister,
    handleSubmit: forgotHandleSubmit,
    reset: forgotReset,
    setValue: forgotSetValue,
    watch: forgotWatch,
    formState: { errors: forgotErrors },
  } = useForm({
    defaultValues: {
      email: emailValue || "",
    },
  });

  useEffect(() => {
    forgotSetValue("email", emailValue);
  }, [emailValue, forgotSetValue]);

  const handleLogin = async (data) => {
    setIsLoading(true);
    try {
      const body = new URLSearchParams();
      body.append("email", data?.email);
      body.append("password", data?.password);
      body.append("user_type", "lawyer");

      const response = await axios.post(`${backend_api}user/signup/`, body, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
      const responseData = response?.data?.data;
      if (responseData?.user_type === "lawyer") {
        // Handle successful login
        dispatch(setToken(responseData?.token));
        // dispatch(setUser(responseData?.user));
        if (responseData?.lawyer?.id) {
          dispatch(setLawerId(responseData?.lawyer?.id));
        }
        dispatch(setUserId(responseData?.id));

        localStorage.setItem(
          "UserId",
          JSON.stringify({
            token: responseData?.token,
            emailId: responseData?.email,
            userType: "lawyer",
          })
        );
        localStorage.setItem("userData", JSON.stringify(responseData?.lawyer));
        navigate("/dashboard");
        toast.success("Login successful!");
      } else if (responseData?.encrypt_key) {
        // Handle new user registration
        setEncryptedKey(responseData?.encrypt_key);
        setShowOtpInput(true);
        toast.info("Please verify your email with OTP");
      }
    } catch (error) {
      if (error.response?.data?.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Network or server error. Please try again.");
      }
      console.error("Login error", error);
    } finally {
      setIsLoading(false);
    }
  };

  // const handleRoleSelect = (type) => {
  //   setUserType(type);
  // };

  // const handleVerifyEmail = () => {
  //   if (userType) {
  //     setOpenSignUpType(false);
  //   }
  // };

  useEffect(() => {
    if (otp.length === 6) {
      const currentFormValues = getValues();
      handleOtpVerify({
        ...currentFormValues,
        otp: otp,
      });
    }
  }, [otp]);

  const handleOtpVerify = async (data) => {
    setIsOtpLoading(true);
    try {
      const body = new URLSearchParams();
      body.append("email", data?.email);
      body.append("otp", otp); // Use the OTP from state instead of form
      body.append("encrypted_key", encryptedKey);
      body.append("user_type", "lawyer");

      const response = await axios.post(`${backend_api}otp-verify`, body, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });

      const responseData = response?.data?.data;
      if (responseData?.user_type === "lawyer") {
        // Handle successful login
        dispatch(setToken(responseData?.token));
        // dispatch(setUser(responseData?.user));
        if (responseData?.lawyer?.id) {
          dispatch(setLawerId(responseData?.lawyer?.id));
        }
        dispatch(setUserId(responseData?.id));

        localStorage.setItem(
          "UserId",
          JSON.stringify({
            token: responseData?.token,
            emailId: responseData?.email,
            userType: "lawyer",
          })
        );
        localStorage.setItem("userData", JSON.stringify(responseData.lawyer));
        localStorage.removeItem("tempUserType");
        navigate("/dashboard");
        toast.success("Sign up completed successfully!");
      }
    } catch (error) {
      if (error.response?.data?.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Network or server error. Please try again.");
      }
      console.error("OTP Verification Error", error);
    } finally {
      setIsOtpLoading(false);
    }
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    setValue(name, value);
    if (userExist === false) {
      trigger("confirmPassword"); // Trigger validation on confirm password
    }
  };

  const handleForgotPassword = async (data) => {
    setIsLoading(true);
    setValue("email", data?.email);
    try {
      const response = await axios.get(
        `${backend_api}user/forget-password/?send_reset_link&email=${data?.email}`,
        data
      );
      toast.success(response?.data?.message);
      setIsEmailSent(true);
    } catch (error) {
      console.error("Forgot Password Error", error);
    }
  };

  const handleCloseForgotPass = () => {
    setOpenForgotPass(false);
    setIsEmailSent(false);
    setIsLoading(false);
  };
  // const handleCloseSignUpType = () => {
  //   setOpenSignUpType(false);
  // };

  const handleFocus = () => {
    const currentValue = getValues("email");
    if (!currentValue) {
      setValue("email", " ");
    }
  };

  const handleBlur = (event) => {
    const { value } = event.target;
    setValue("email", value.trim()); // Trim the value and update it
  };

  const getFormActionText = () => {
    if (userExist === null) return "Login";
    return userExist ? "Login" : "Sign Up";
  };

  return (
    <div className="min-h-screen flex flex-col-reverse 800px:flex-row">
      <motion.div
        initial={{ opacity: 0, x: -100 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.8 }}
        className="w-full 800px:w-1/2 bg-white flex flex-col justify-center items-center p-6 overflow-hidden relative"
      >
        <Box
          sx={{
            width: "100%",
            maxWidth: "27.5rem",
            p: "2rem",
            zIndex: 5,
            borderRadius: "1rem",
            backdropFilter: "blur(4px)",
            WebkitBackdropFilter: "blur(4px)",
            border: "1px solid #e5e5e5",
            transition: "all 0.3ms",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              mb: 4,
            }}
          >
            <Box sx={{ height: "2.5rem", mb: 2 }}>
              <img
                src={companyLogo}
                alt="Company Logo"
                style={{
                  height: "100%",
                  width: "auto",
                  objectFit: "contain",
                }}
                onError={(e) => {
                  e.onerror = null;
                  e.target.src = require("../assets/nav-lexmom.webp");
                }}
              />
            </Box>
            <Typography variant="h6" color="primary" sx={{ fontWeight: 600 }}>
              Log In / Sign Up
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
              Enter your credentials to access your account
            </Typography>
          </Box>

          <form
            className="space-y-4 w-full max-w-sm"
            onSubmit={handleSubmit(
              encryptedKey ? handleOtpVerify : handleLogin
            )}
          >
            <TextField
              size="small"
              fullWidth
              label="Email"
              type="email"
              variant="outlined"
              error={!!errors.email}
              helperText={errors.email?.message}
              {...register("email", {
                required: "Email is required",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Invalid email address",
                },
              })}
              InputLabelProps={{
                shrink: !!emailValue || emailValue === " ",
              }}
              onFocus={handleFocus}
              onBlur={handleBlur}
              onChange={(e) => handleCheckEmail(e.target.value || emailValue)}
            />
            <Box>
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}
              >
                <TextField
                  size="small"
                  fullWidth
                  label="Password"
                  type={showPassword ? "text" : "password"}
                  variant="outlined"
                  name="password"
                  onChange={handlePasswordChange}
                  error={!!errors.password}
                  helperText={errors.password?.message}
                  {...register("password", {
                    required: "Password is required",
                    minLength: {
                      value: 6,
                      message: "Password must be at least 6 characters",
                    },
                  })}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowPassword}
                          edge="end"
                          color="primary"
                        >
                          {showPassword ? (
                            <VisibilityOffRounded />
                          ) : (
                            <VisibilityRounded />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                {userExist === false && (
                  <TextField
                    size="small"
                    fullWidth
                    label="Confirm Password"
                    type={showPassword ? "text" : "password"}
                    variant="outlined"
                    name="confirmPassword"
                    onChange={handlePasswordChange}
                    error={!!errors.confirmPassword || !!passwordError}
                    helperText={
                      errors.confirmPassword?.message || passwordError
                    }
                    {...register("confirmPassword", {
                      validate: (value) =>
                        value === watch("password") || "Passwords do not match",
                    })}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleClickShowPassword}
                            edge="end"
                            color="primary"
                          >
                            {showPassword ? (
                              <VisibilityOffRounded />
                            ) : (
                              <VisibilityRounded />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              </Box>

              {userExist || userExist === null ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    onClick={() => setOpenForgotPass(true)}
                    variant="body1"
                    color="text.secondary"
                    sx={{
                      fontSize: "0.8rem",
                      cursor: "pointer",
                      textDecoration: "underline",
                      fontWeight: 500,
                      "&:hover": {
                        textDecoration: "none",
                      },
                    }}
                  >
                    Forgot password?
                  </Typography>
                </Box>
              ) : null}
            </Box>
            {showOtpInput && (
              <Box sx={{ mt: 2 }}>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ mb: "1rem" }}
                >
                  Enter the verification code sent to your email
                </Typography>
                <OTP
                  separator={<span>-</span>}
                  value={otp}
                  onChange={setOtp}
                  length={6}
                />
              </Box>
            )}
            <LoadingButton
              type="submit"
              loading={showOtpInput ? isOtpLoading : isLoading}
              loadingPosition="start"
              startIcon={showOtpInput ? <VerifiedUserIcon /> : <LoginIcon />}
              variant="contained"
              fullWidth
              sx={{ p: "0.4rem", fontSize: "1rem" }}
              disabled={showOtpInput && otp.length !== 5}
            >
              {getFormActionText()}
            </LoadingButton>
          </form>
        </Box>
        <Box
          sx={{
            position: "absolute",
            width: "100%",
            height: "100%",
            overflow: "hidden",
            zIndex: 1,
            top: 0,
            left: 0,
          }}
        >
          <RetroGridBg />
        </Box>
      </motion.div>

      <motion.div
        initial={{ opacity: 0, x: 100 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.8 }}
        className="w-full 800px:w-1/2 relative text-white flex items-center justify-center flex-col p-5"
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            bgcolor: "#004242",
            borderRadius: "3rem",
          }}
        >
          <Box sx={{ height: "20rem" }}>
            <img
              src={LawyerGroupImg}
              alt="Company Logo"
              style={{
                height: "100%",
                width: "100%",
                objectFit: "contain",
              }}
              onError={(e) => {
                e.onerror = null;
                e.target.src = require("../assets/no-preview.webp");
              }}
            />
          </Box>

          <div className="relative z-10 text-center mt-4">
            <h2 className="text-4xl font-bold">Welcome to Our Platform</h2>
            <p className="mt-4 text-lg">
              Join us and enjoy exclusive features designed just for you!
            </p>
          </div>
        </Box>
      </motion.div>

      {/* DIALOG FOR SIGNUP  USER TYPE  */}
      {/* 
      <Dialog
        open={openSignupType}
        onClose={handleCloseSignUpType}
        maxWidth="md"
        PaperProps={{
          elevation: 3,
          sx: { borderRadius: 3, p: 3 },
        }}
      >
        <DialogTitle>
          <Typography variant="h5" sx={{ fontWeight: 700, mb: 1 }}>
            Choose Your Role
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Select the role that best describes you to get started
          </Typography>
        </DialogTitle>

        <DialogContent sx={{ mt: 2 }}>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: { xs: "1fr", sm: "1fr 1fr" },
              gap: "1rem",
              mb: 3,
              p: "1rem",
            }}
          >
            {roles?.map((role) => (
              <RoleCard
                key={role.title}
                title={role?.title}
                description={role?.description}
                icon={role?.icon}
                color={userType === role?.value ? "#00B3B0" : "#666666"}
                onClick={() => handleRoleSelect(role?.value)}
                selected={userType === role?.value}
              />
            ))}
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              gap: 2,
              mt: 2,
            }}
          >
            <Button
              variant="contained"
              endIcon={<MarkEmailReadRoundedIcon />}
              disabled={!userType}
              onClick={handleVerifyEmail}
            >
              Continue
            </Button>
          </Box>
        </DialogContent>
      </Dialog> */}

      {/* DIALOG FOR FORGOT PASSWORD OR RESET PASSWORD  */}
      <Dialog
        open={openForgotPass}
        onClose={handleCloseForgotPass}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          elevation: 3,
          sx: { borderRadius: 2, p: 2 },
        }}
      >
        <DialogTitle sx={{ pb: 1 }}>
          <Typography variant="h6" component="div" fontWeight="bold">
            {isEmailSent ? null : "Reset Password"}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={handleCloseForgotPass}
            sx={(theme) => ({
              position: "absolute",
              right: 8,
              top: 8,
              color: theme.palette.grey[500],
            })}
          >
            <CloseRounded />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {isEmailSent ? (
            <Box sx={{ textAlign: "center", py: 3 }}>
              <Box sx={{ height: "10rem" }}>
                <img
                  src={emailSend}
                  alt="Company Logo"
                  style={{
                    height: "100%",
                    width: "100%",
                    objectFit: "contain",
                  }}
                  onError={(e) => {
                    e.onerror = null;
                    e.target.src = require("../assets/no-preview.webp");
                  }}
                />
              </Box>
              <Typography
                variant="body1"
                color="primary.main"
                sx={{ pb: 2, fontSize: "1.2rem", fontWeight: "500" }}
              >
                Check your email
              </Typography>
              <Typography
                variant="body1"
                color="text.secondary"
                sx={{ fontSize: "0.9rem" }}
              >
                We've sent a password reset link to your email address. Please
                check your inbox and follow the instructions to reset your
                password.
              </Typography>
              <Typography
                variant="body1"
                color="text.secondary"
                sx={{ fontSize: "0.8rem" }}
              >
                {forgotWatch("email")}
              </Typography>
            </Box>
          ) : (
            <form onSubmit={forgotHandleSubmit(handleForgotPassword)}>
              <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
                Enter your email address and we'll send you a link to reset your
                password.
              </Typography>

              <TextField
                fullWidth
                size="small"
                label="Email Address"
                variant="outlined"
                error={!!forgotErrors.email}
                helperText={forgotErrors.email?.message}
                {...forgotRegister("email", {
                  required: "Email is required",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Invalid email address",
                  },
                })}
                sx={{ mb: 3 }}
              />

              <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}>
                <Button
                  variant="outlined"
                  onClick={handleCloseForgotPass}
                  disabled={isLoading}
                >
                  Cancel
                </Button>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  loading={isLoading}
                  loadingPosition="start"
                  startIcon={<SendRoundedIcon />}
                >
                  Send Reset Link
                </LoadingButton>
              </Box>
            </form>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default SignupPage;

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};

const InputElement = styled("input")(
  ({ theme }) => `
  width: 2.5rem;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 8px 0;
  border-radius: 8px;
  text-align: center;
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid #0000003b;
  box-shadow: 0 2px 4px ${
    theme.palette.mode === "dark" ? "rgba(0,0,0, 0.5)" : "rgba(0,0,0, 0.05)"
  };

  &:hover {
    border-color: #1C2025;
  }

  &:focus {
    border-color: #00B3B0;
  }

  /* firefox */
  &:focus-visible {
    outline: 0;
  }
`
);
