import { Box } from "@mui/material";
import React from "react";
import ReactApexChart from "react-apexcharts";

const ProgressBarChart = () => {
  const chartOptions = {
    chart: {
      type: "bar",
      height: 250,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "50%",
        endingShape: "rounded",
        borderRadius: 4,
        distributed: true,
      },
    },
    dataLabels: {
      enabled: false,
      formatter: function (val) {
        return "$" + val.toLocaleString();
      },
      style: {
        fontSize: "12px",
        color: "red",
      },
    },
    legend: {
      show: true,
      position: "bottom",
      horizontalAlign: "center",
    },
    stroke: {
      show: false,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: ["Actual", "Expected", "Target"],
    },
    yaxis: {
      title: {
        text: "Amount ($)",
      },
      labels: {
        formatter: function (val) {
          return "$" + val.toLocaleString();
        },
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return "$ " + val.toLocaleString();
        },
      },
    },
    colors: ["#4ade80", "#60a5fa", "#f472b6"],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: "100%",
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  const series = [
    {
      name: "Progress",
      data: [75000, 100000, 150000],
    },
  ];

  return (
    <Box>
      <ReactApexChart
        options={chartOptions}
        series={series}
        type="bar"
        height="250"
        width="100%"
      />
    </Box>
  );
};

export default ProgressBarChart;
