import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";

const LineDataChart = () => {
  const originalTargets = [
    10000, 20000, 30000, 40000, 50000, 60000, 70000, 80000, 90000, 100000,
  ];
  const distributedTargets = [
    10000, 10000, 20000, 30000, 40000, 50000, 60000, 70000, 80000, 90000,
    100000, 100000,
  ];

  const [series, setSeries] = useState([
    {
      name: "Target",
      data: distributedTargets,
    },
    {
      name: "Actual",
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    },
  ]);

  const [options, setOptions] = useState({
    chart: {
      height: "100%",
      type: "area",
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      type: "category",
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      labels: {
        rotate: -45,
        rotateAlways: true,
        hideOverlappingLabels: true,
        style: {
          fontSize: "12px",
          fontWeight: 400,
          fontFamily: "Helvetica, Arial, sans-serif",
          color: "#9e9e9e",
        },
      },
    },
    tooltip: {
      x: {
        format: "MMM",
      },
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          xaxis: {
            labels: {
              rotate: 0,
              rotateAlways: true,
              hideOverlappingLabels: true,
            },
          },
        },
      },
    ],
  });

  return (
    <div className="w-full h-full transition-all ms-0.5 ease-linear">
      <ReactApexChart
        options={options}
        series={series}
        type="area"
        height="100%"
      />
    </div>
  );
};

export default LineDataChart;
