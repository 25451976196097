// Material-UI Icons
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import CalendarMonthRoundedIcon from "@mui/icons-material/CalendarMonthRounded";
import TaskRoundedIcon from "@mui/icons-material/TaskRounded";
import AnalyticsRoundedIcon from "@mui/icons-material/AnalyticsRounded";
import ContactsRoundedIcon from "@mui/icons-material/ContactsRounded";
import TuneRoundedIcon from "@mui/icons-material/TuneRounded";
import RequestQuoteRoundedIcon from "@mui/icons-material/RequestQuoteRounded";
import AccountBalanceRoundedIcon from "@mui/icons-material/AccountBalanceRounded";
import FolderRoundedIcon from "@mui/icons-material/FolderRounded";
import QuestionAnswerRoundedIcon from "@mui/icons-material/QuestionAnswerRounded";
import AccessTimeRoundedIcon from "@mui/icons-material/AccessTimeRounded";
import AppSettingsAltRoundedIcon from "@mui/icons-material/AppSettingsAltRounded";
import DescriptionRoundedIcon from "@mui/icons-material/DescriptionRounded";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import ListRoundedIcon from "@mui/icons-material/ListRounded";
import HistoryRoundedIcon from "@mui/icons-material/HistoryRounded";
import StairsRoundedIcon from "@mui/icons-material/StairsRounded";
import ViewQuiltRoundedIcon from "@mui/icons-material/ViewQuiltRounded";
import HistoryEduRoundedIcon from "@mui/icons-material/HistoryEduRounded";
import RuleFolderRoundedIcon from "@mui/icons-material/RuleFolderRounded";
import LowPriorityRoundedIcon from "@mui/icons-material/LowPriorityRounded";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import BusinessRoundedIcon from "@mui/icons-material/BusinessRounded";

import { lazy } from "react";
import { Box, Typography, Button } from "@mui/material";

export const userData = JSON.parse(localStorage.getItem("UserId"));
export const lawyerData = JSON.parse(localStorage.getItem("userData"));
export const profileCompleted = Number(
  localStorage.getItem("profileCompleted")
);

export const menuItems = {
  products: [
    { title: "Product Overview", link: "/product1" },
    { title: "Prosecution Manager", link: "/product2" },
    { title: "Lexmom Plus", link: "/product3" },
    { title: "Invention Manager", link: "/product3" },
    { title: "Pricing", link: "/product3" },
    { title: "Free Trial", link: "/product3" },
  ],

  company: [
    { title: "About Us", link: "/about-us" },
    { title: "Why Lexmom", link: "/careers" },
    { title: "Career", link: "/investors" },
    { title: "Blog", link: "/investors" },
    { title: "Forum", link: "/investors" },
    { title: "Testimonials", link: "/investors" },
    { title: "Learning Center", link: "/investors" },
  ],
};

// Extended menu with sub-items
export const DashboardSideMenu = [
  {
    mIcon: <HomeRoundedIcon />,
    mText: "Dashboard",
    path: "/dashboard",
    subMenu: [
      {
        mText: "Team",
        path: "/dashboard/team-members",
        mIcon: <Diversity3Icon />,
      },
    ],
  },
  {
    mIcon: <CalendarMonthRoundedIcon />,
    mText: "Calendar",
    path: "/dashboard/calendars",
  },
  {
    mIcon: <TaskRoundedIcon />,
    mText: "Tasks",
    path: "/dashboard/tasks",
    subMenu: [
      {
        mText: "Task Lists",
        path: "/dashboard/task/lists",
        mIcon: <ListRoundedIcon />,
      },
      {
        mText: "Task Type",
        path: "/dashboard/task/type",
        mIcon: <LowPriorityRoundedIcon />,
      },
      {
        mText: "Task Feed",
        path: "/dashboard/tasks/feeds",
        mIcon: <HistoryRoundedIcon />,
      },
    ],
  },
  {
    mIcon: <AnalyticsRoundedIcon />,
    mText: "Matters",
    path: "/dashboard/matter",
    subMenu: [
      {
        mText: "Stages",
        path: "/dashboard/matter-stages",
        mIcon: <StairsRoundedIcon />,
      },
      {
        mText: "Templates",
        path: "/dashboard/matter-template",
        mIcon: <ViewQuiltRoundedIcon />,
      },
    ],
  },
  {
    mIcon: <ContactsRoundedIcon />,
    mText: "Contacts",
    path: "/dashboard/contact-details",
    // subMenu: [
    //   {
    //     mText: "Client",
    //     path: "/dashboard/contacts/Client",
    //     mIcon: <PersonRoundedIcon />,
    //   },
    //   {
    //     mText: "Organization",
    //     path: "/dashboard/contacts/organization",
    //     mIcon: <AddRoundedIcon />,
    //   },
    // ],
  },
  {
    mIcon: <TuneRoundedIcon />,
    mText: "Activities",
    path: "/dashboard/activities",
  },
  {
    mIcon: <RequestQuoteRoundedIcon />,
    mText: "Billing",
    path: "/dashboard/bills",
  },
  {
    mIcon: <AccountBalanceRoundedIcon />,
    mText: "Accounts",
    path: "/dashboard/bank_accounts",
  },
  {
    mIcon: <FolderRoundedIcon />,
    mText: "Documents",
    path: "/dashboard/documents",
    subMenu: [
      {
        mText: "Templates",
        path: "/dashboard/documents/templates",
        mIcon: <RuleFolderRoundedIcon />,
      },
      {
        mText: "Categories",
        path: "/dashboard/documents/categories",
        mIcon: <HistoryEduRoundedIcon />,
      },
    ],
  },
  {
    mIcon: <QuestionAnswerRoundedIcon />,
    mText: "Communications",
    path: "/dashboard/communications",
  },
  {
    mIcon: <AccessTimeRoundedIcon />,
    mText: "Reports",
    path: "/dashboard/reports",
  },
  {
    mIcon: <AppSettingsAltRoundedIcon />,
    mText: "App Integrations",
    // path: "/dashboard/app_integrations",
  },
  {
    mIcon: <DescriptionRoundedIcon />,
    mText: "Custom Fields",
    path: "/dashboard/custom_fields",
  },
  {
    mIcon: <SettingsRoundedIcon />,
    mText: "Settings",
    path: "/dashboard/settings",
  },
];

export const personalDashBillData = [
  { title: "Draft Bills", num: 0, btnText: "create new bill" },
  { title: "Total in Draft ", num: 0 },
  { title: "Unpaid Bills", num: 0 },
  { title: "Total in Unpaid ", num: 0 },
  { title: "Overdue Bills", num: 0 },
  { title: "Total in Overdue", num: 0 },
];

export const personalDashOtherData = [
  { title: "Team Members", DataCout: 0 },
  { title: "Total Client", DataCout: 0 },
  { title: "Total Contacts", DataCout: 0 },
  { title: "Total Matter", DataCout: 0 },
  { title: "Pending Matter", DataCout: 0 },
];
export const caseFinancialData = [
  {
    title: "Work in progress ",
    num: 0,
    btnText: "Quick bill",
    unbilled: "$0.00",
    draft: "$0.00",
  },
  { title: "Outstanding balance ", num: 0, btnText: "view billes" },
  {
    title: "Matter trust funds",
    num: 0,
    btnText: "New request",
    minTrustHold: "$0.00",
  },
  {
    title: "Matter Budget",
    num: 0,
    usedBudget: 10,
  },
];

export const genders = ["male", "female", "others"];

export const taskFilter = [
  "All dates",
  "Past due",
  "Today",
  "Tomorrow",
  "This week",
  "This month",
  "This year",
  "No due date",
];

export const createNewOpenFormName = [
  { label: "Add Team Member", value: "add_team_member" },
  { label: "Add Agenda", value: "add_agenda" },
  { label: "Add Task", value: "add_task" },
  { label: "Add Task List", value: "add_task_list" },
  { label: "Add Task type", value: "add_task_type" },
  { label: "Add Matter", value: "add_matter" },
  { label: "Add Contact", value: "add_contact" },
  { label: "New Bill", value: "new_bill" },
  { label: "Record Payment", value: "record_payment" },
  { label: "New Trust request", value: "new_trust_request" },
  { label: "New Account", value: "new_account" },
  { label: "New Transaction", value: "new_transaction" },
  { label: "Transfer funds", value: "transfer_funds" },
];

// this for tag loading pattern in contact tags
export const CHIP_PATTERNS = [
  [89, 70, 60, 80], // Row 1
  [90, 60, 50], // Row 2
  [40, 50, 70, 60, 80], // Row 3
  [60, 50, 90], // Row 4
];

export const handleMatterNewTab = () => {
  const baseUrl = window.location.origin;
  console.log(baseUrl);

  const newUrl = `${baseUrl}/dashboard/matter`;

  const newTab = window.open(newUrl, "_blank");

  if (newTab) {
    newTab.onload = () => {
      newTab.sessionStorage.setItem("dialogCase", "true");
    };
  }
};

export const NoDataOptionField = ({
  imgSrc,
  text,
  btnText,
  handleClickNoData,
  endIcon,
}) => {
  return (
    <>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "0.5rem",
        }}
      >
        <Box sx={{ height: "6rem" }}>
          <img
            src={imgSrc}
            alt="No Data"
            style={{
              height: "100%",
              width: "100%",
              objectFit: "contain",
            }}
            onError={(e) => {
              e.onerror = null;
              e.target.src = require("../assets/no-option-found.webp");
            }}
          />
        </Box>
        <Typography
          variant="body1"
          sx={{
            fontSize: "0.9rem",
            textAlign: "center",
          }}
        >
          {text}
        </Typography>
        {btnText && handleClickNoData ? (
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={handleClickNoData}
            endIcon={endIcon}
          >
            Create {btnText}
          </Button>
        ) : null}
      </Box>
    </>
  );
};
export const ListboxComponent = ({
  btnIcon,
  btnText,
  handleClikListBtn,
  children,
  ...other
}) => (
  <Box sx={{ position: "relative" }}>
    <ul
      {...other}
      style={{
        maxHeight: "12.5rem",
        overflowY: "auto",
        margin: 0,
        padding: 0,
      }}
    >
      {children}
    </ul>
    <Box
      sx={{
        position: "sticky",
        bottom: 0,
        borderTop: "1px solid #e5e5e5",
        backgroundColor: "white",
        padding: "8px",
        display: "flex",
        justifyContent: "flex-end",
      }}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <Button
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          handleClikListBtn();
        }}
        onMouseDown={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        variant="text"
        size="small"
        color="primary"
        endIcon={btnIcon}
      >
        {btnText}
      </Button>
    </Box>
  </Box>
);

export const DataGridNoData = ({ imgSrc, text }) => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      height: "100%",
      p: "2rem",
      gap: "1rem",
    }}
  >
    <Box sx={{ height: "10rem" }}>
      <img
        src={imgSrc}
        alt="Company Logo"
        style={{
          height: "100%",
          width: "100%",
          objectFit: "cover",
        }}
        onError={(e) => {
          e.onerror = null;
          e.target.src = require("../assets/no-preview.webp");
        }}
      />
    </Box>
    <Typography variant="body1">{text}</Typography>
  </Box>
);

export const renderContactOption = (props, option) => (
  <li
    {...props}
    style={{
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    }}
  >
    <Typography variant="body1">
      {option?.first_name
        ? `${option?.first_name} ${option?.last_name}`
        : option?.company_name
        ? option?.company_name
        : ""}
    </Typography>
    <Typography variant="body1">
      {option?.contact_type === "person" ? (
        <AccountCircleRoundedIcon
          sx={{ color: "#bf3fe1", fontSize: "0.9rem" }}
        />
      ) : (
        <BusinessRoundedIcon sx={{ color: "#2e77f5", fontSize: "0.9rem" }} />
      )}
    </Typography>
  </li>
);

// delete able dummydata this is not using any where can be removed ---------------------------------------------

export const currencyList = [
  { label: "🇺🇸 USD - United States Dollar ($)", value: "USD", symbol: "$" },
  { label: "🇪🇺 EUR - Euro (€)", value: "EUR", symbol: "€" },
  { label: "🇬🇧 GBP - British Pound (£)", value: "GBP", symbol: "£" },
  { label: "🇯🇵 JPY - Japanese Yen (¥)", value: "JPY", symbol: "¥" },
  { label: "🇦🇺 AUD - Australian Dollar (A$)", value: "AUD", symbol: "A$" },
  { label: "🇨🇦 CAD - Canadian Dollar (C$)", value: "CAD", symbol: "C$" },
  { label: "🇨🇭 CHF - Swiss Franc (CHF)", value: "CHF", symbol: "CHF" },
  { label: "🇨🇳 CNY - Chinese Yuan (¥)", value: "CNY", symbol: "¥" },
  { label: "🇮🇳 INR - Indian Rupee (₹)", value: "INR", symbol: "₹" },
  { label: "🇧🇷 BRL - Brazilian Real (R$)", value: "BRL", symbol: "R$" },
  { label: "🇿🇦 ZAR - South African Rand (R)", value: "ZAR", symbol: "R" },
  { label: "🇳🇿 NZD - New Zealand Dollar (NZ$)", value: "NZD", symbol: "NZ$" },
  { label: "🇲🇽 MXN - Mexican Peso (MX$)", value: "MXN", symbol: "MX$" },
];

export const paymentMethods = [
  { label: "Direct - Check", value: "check" },
  { label: "Direct - Cash", value: "cash" },
  { label: "Direct - Credit Card", value: "credit_card" },
  { label: "Direct - Debit Card", value: "debit_card" },
  { label: "Direct - eCheck (ACH) Payments", value: "ach" },
  { label: "Direct - Apple Pay", value: "apple_pay" },
  { label: "Direct - Google Pay", value: "google_pay" },
  { label: "Direct - Bank Transfer (Wire Transfer)", value: "bank_transfer" },
  { label: "Other - Money Order", value: "money_order" },
  { label: "Other - Bank Draft", value: "bank_draft" },
  { label: "Other - Third-Party Payment", value: "third_party" },
  { label: "Other", value: "other" },
];

export const columns = [
  {
    field: "id",
    headerName: "ID",
    width: 100,
  },
  {
    field: "firstName",
    headerName: "First Name",
    width: 130,
  },
  {
    field: "lastName",
    headerName: "Last Name",
    width: 130,
  },
  {
    field: "age",
    headerName: "Age",
    width: 100,
  },
  {
    field: "email",
    headerName: "Email",
    width: 200,
  },
  {
    field: "phone",
    headerName: "Phone",
    width: 150,
  },
  {
    field: "address",
    headerName: "Address",
    width: 250,
  },
  {
    field: "city",
    headerName: "City",
    width: 130,
  },
  {
    field: "country",
    headerName: "Country",
    width: 130,
  },
  {
    field: "occupation",
    headerName: "Occupation",
    width: 180,
  },
  {
    field: "department",
    headerName: "Department",
    width: 150,
  },
  {
    field: "salary",
    headerName: "Salary",
    width: 120,
  },
  {
    field: "startDate",
    headerName: "Start Date",
    width: 130,
  },
  {
    field: "status",
    headerName: "Status",
    width: 120,
  },
  {
    field: "rating",
    headerName: "Rating",
    width: 120,
  },
];

export const rows = [
  {
    id: 1,
    firstName: "John",
    lastName: "Snow",
    age: 30,
    email: "john.snow@email.com",
    phone: "(555) 123-4567",
    address: "123 Winter Street",
    city: "Winterfell",
    country: "Westeros",
    occupation: "Lord Commander",
    department: "Night's Watch",
    salary: 75000,
    startDate: "2020-01-15",
    status: "Active",
    rating: 4.8,
  },
  {
    id: 2,
    firstName: "Daenerys",
    lastName: "Targaryen",
    age: 28,
    email: "dany.t@email.com",
    phone: "(555) 234-5678",
    address: "456 Dragon Road",
    city: "Meereen",
    country: "Essos",
    occupation: "Queen",
    department: "Leadership",
    salary: 120000,
    startDate: "2019-06-20",
    status: "Active",
    rating: 4.9,
  },
  {
    id: 3,
    firstName: "Tyrion",
    lastName: "Lannister",
    age: 39,
    email: "tyrion.l@email.com",
    phone: "(555) 345-6789",
    address: "789 Lion's Path",
    city: "King's Landing",
    country: "Westeros",
    occupation: "Hand of the Queen",
    department: "Advisory",
    salary: 95000,
    startDate: "2018-12-10",
    status: "Active",
    rating: 4.7,
  },
  {
    id: 4,
    firstName: "Arya",
    lastName: "Stark",
    age: 20,
    email: "arya.stark@email.com",
    phone: "(555) 456-7890",
    address: "321 Wolf Road",
    city: "Braavos",
    country: "Essos",
    occupation: "Assassin",
    department: "Special Ops",
    salary: 85000,
    startDate: "2021-03-25",
    status: "Active",
    rating: 4.6,
  },
  {
    id: 5,
    firstName: "Cersei",
    lastName: "Lannister",
    age: 45,
    email: "cersei.l@email.com",
    phone: "(555) 567-8901",
    address: "654 Crown Street",
    city: "King's Landing",
    country: "Westeros",
    occupation: "Queen Regent",
    department: "Leadership",
    salary: 150000,
    startDate: "2017-08-30",
    status: "Inactive",
    rating: 3.5,
  },
];
