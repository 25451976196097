import React from "react";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import BusinessRoundedIcon from "@mui/icons-material/BusinessRounded";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import AttachMoneyRoundedIcon from "@mui/icons-material/AttachMoneyRounded";
import ArrowOutwardRoundedIcon from "@mui/icons-material/ArrowOutwardRounded";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import axios from "axios";
import { toast } from "react-toastify";
import { useDialog } from "../../../DialogContext";
import {
  getCases,
  getPracticeArea,
} from "../../../../redux/Apps/Lawyerapp/CaseSlice/CaseSlice";
import { backend_api } from "../../../../static/server";
import {
  getAllAccounts,
  getAllStatement,
} from "../../../../redux/Apps/Lawyerapp/AccountSlice/AccountSlice";
import { getAllFilterContacts } from "../../../../redux/Apps/Lawyerapp/ContactSlice/ContactSlice";
import { getAllCustomField } from "../../../../redux/Apps/Lawyerapp/CustomFieldSlice";
import {
  currencyList,
  handleMatterNewTab,
  ListboxComponent,
  NoDataOptionField,
  renderContactOption,
} from "../../../../static/staticdata";
import {
  generateUniqueMatterName,
  pageScrollStyle,
} from "../../../../styles/styles";
import noOption from "../../../../assets/no-option-found.webp";

const TranferFundFrom = ({ accountId }) => {
  const { closeDialog } = useDialog();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { token } = useSelector((data) => data.user);
  const { allCase, loading: caseLoading } = useSelector((state) => state.case);
  const { filteredContacts } = useSelector((state) => state.contact);
  const { AllAccounts } = useSelector((state) => state.account);

  useEffect(() => {
    dispatch(getCases({ url: `${backend_api}case/register/`, token: token }));
    dispatch(
      getAllAccounts({ url: `${backend_api}bank/accounts/`, token: token })
    );
    dispatch(
      getPracticeArea({
        url: `${backend_api}case/case-type/?case_service_type=practice_area`,
        token: token,
      })
    );
    dispatch(
      getAllFilterContacts({
        url: `${backend_api}contact/retrieve/`,
        token: token,
      })
    );
    dispatch(
      getAllCustomField({
        url: `${backend_api}custom-field/?field_for=case`,
        token: token,
      })
    );
  }, [dispatch, token]);

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {},
  });
  const onSubmit = async (data) => {
    const sourceWithdrawData = {
      transaction_type: "withdrawal",
      account: data?.source_account,
      date: data?.date,
      client: data?.client,
      matter: data?.matter,
      source_account: data?.source_account,
      destination_account: data?.destination_account,
      payer_payee: data?.payer_payee,
      currency: data?.currency,
      amount: data?.amount,
      exchange_rate: data?.exchange_rate,
      description: data?.description,
      status: "completed",
    };

    const destinationDepositData = {
      transaction_type: "deposit",
      account: data?.destination_account,
      date: data?.date,
      client: data?.client,
      matter: data?.matter,
      source_account: data?.source_account,
      destination_account: data?.destination_account,
      payer_payee: data?.payer_payee,
      currency: data?.currency,
      amount: data?.amount,
      exchange_rate: data?.exchange_rate,
      description: data?.description,
      status: "completed",
    };

    try {
      await Promise.all([
        axios.post(`${backend_api}bank/transactions/`, sourceWithdrawData, {
          headers: { Authorization: `Token ${token}` },
        }),
        axios.post(`${backend_api}bank/transactions/`, destinationDepositData, {
          headers: { Authorization: `Token ${token}` },
        }),
      ]);

      toast.success("Funds transferred successfully");
      dispatch(
        getAllStatement({
          url: `${backend_api}bank/transactions/?account=${accountId}`,
          token: token,
        })
      );
      reset();
      closeDialog();
    } catch (error) {
      toast.error("Transaction failed. Please try again.");
    }
  };

  return (
    <>
      <Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            sx={{
              p: "1rem",
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
            }}
          >
            <Box sx={{ display: "flex", gap: "1rem" }}>
              <Controller
                name="between_to"
                control={control}
                render={({ field }) => (
                  <FormControl size="small" fullWidth>
                    <InputLabel
                      id="between_to-label"
                      sx={{ bgcolor: "#fff", px: "0.3rem" }}
                    >
                      Between to
                    </InputLabel>
                    <Select
                      {...field}
                      labelId="between_to-label"
                      defaultValue="bank_ac"
                    >
                      <MenuItem value={"bank_ac"}>Bank Accounts</MenuItem>
                      <MenuItem disabled value={"matters"}>
                        Matters
                      </MenuItem>
                    </Select>
                  </FormControl>
                )}
              />
              <Controller
                name="client"
                control={control}
                rules={{ required: "Client is required" }}
                render={({ field }) => (
                  <FormControl fullWidth>
                    <Autocomplete
                      disablePortal
                      options={filteredContacts?.data || []}
                      getOptionLabel={(option) =>
                        option?.first_name
                          ? `${option?.first_name} ${option?.last_name}`
                          : option?.company_name || ""
                      }
                      value={
                        filteredContacts?.data.find(
                          (contact) => contact.id === field.value
                        ) || null
                      }
                      onChange={(_, newValue) =>
                        field.onChange(newValue ? newValue.id : null)
                      }
                      isOptionEqualToValue={(option, value) =>
                        option?.id === value
                      }
                      noOptionsText="No contact found"
                      renderOption={renderContactOption}
                      renderInput={(params) => {
                        const { InputProps, ...rest } = params;
                        return (
                          <TextField
                            {...rest}
                            label={
                              <span>
                                Client
                                <span style={{ color: "red" }}>*</span>
                              </span>
                            }
                            size="small"
                            InputProps={{
                              ...InputProps,
                              startAdornment: field.value ? (
                                <Chip
                                  size="small"
                                  key={field.value}
                                  sx={{
                                    bgcolor:
                                      filteredContacts?.data?.find(
                                        (contact) => contact.id === field.value
                                      )?.contact_type === "person"
                                        ? "#bf3fe1"
                                        : "#2e77f5",
                                    color: "#fff",
                                  }}
                                  label={
                                    <Typography variant="body1">
                                      {filteredContacts?.data.find(
                                        (contact) => contact.id === field.value
                                      )?.contact_type === "person" ? (
                                        <AccountCircleRoundedIcon
                                          sx={{
                                            color: "#fff",
                                            fontSize: "0.9rem",
                                          }}
                                        />
                                      ) : (
                                        <BusinessRoundedIcon
                                          sx={{
                                            color: "#fff",
                                            fontSize: "0.9rem",
                                          }}
                                        />
                                      )}
                                    </Typography>
                                  }
                                />
                              ) : null,
                            }}
                          />
                        );
                      }}
                    />
                  </FormControl>
                )}
              />
            </Box>
            <Box>
              <Controller
                name="date"
                control={control}
                rules={{ required: "Date is required" }}
                render={({ field, value }) => (
                  <DatePicker
                    {...field}
                    value={field.value ? dayjs(field.value) : null}
                    onChange={(newValue) => {
                      const isValidDate = newValue && dayjs(newValue).isValid();
                      field.onChange(
                        isValidDate
                          ? dayjs(newValue).startOf("day").format("YYYY-MM-DD")
                          : null
                      );
                    }}
                    sx={{
                      width: "100%",
                    }}
                    slotProps={{
                      textField: {
                        label: (
                          <span>
                            Date<span style={{ color: "red" }}>*</span>
                          </span>
                        ),
                        size: "small",
                        variant: "outlined",
                        fullWidth: true,
                      },
                      popper: {
                        sx: {
                          "& .MuiPickersPopper-root": {
                            maxWidth: "100%",
                            maxHeight: "100%",
                            overflow: "hidden",
                          },
                          "& .MuiPaper-root": {
                            width: "fit-content",
                            maxHeight: "15rem",
                            overflowY: "auto",
                            ...pageScrollStyle,
                          },
                        },
                      },
                    }}
                    format="MM/DD/YYYY"
                  />
                )}
              />
            </Box>

            <Controller
              name="matter"
              control={control}
              rules={{ required: "Matter is required" }}
              render={({ field }) => (
                <FormControl size="small" fullWidth error={errors?.case}>
                  <Autocomplete
                    {...field}
                    options={allCase || []}
                    getOptionLabel={(option) => {
                      if (!option) return "";
                      return generateUniqueMatterName(
                        option.id,
                        option.case_name
                      );
                    }}
                    value={
                      field.value
                        ? allCase?.find((item) => item.id === field.value)
                        : null
                    }
                    onChange={(_, newValue) =>
                      field.onChange(newValue?.id || null)
                    }
                    renderInput={(params) => (
                      <TextField {...params} label="Matter" size="small" />
                    )}
                    renderOption={(props, option) => (
                      <li {...props}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                            alignItems: "center",
                          }}
                        >
                          <Typography>
                            {option.id
                              ? `000${option.id}-${option.case_name}`
                              : ""}
                          </Typography>
                          <Typography
                            variant="body1"
                            sx={{
                              p: "0.2rem 0.5rem",
                              borderRadius: "1rem",
                              textAlign: "center",
                              fontSize: "0.7rem",
                              bgcolor:
                                option?.case_status === "open"
                                  ? "#4CAF50"
                                  : option?.case_status === "pending"
                                  ? "#FFC107"
                                  : "#F44336",
                              color: "#fff",
                            }}
                          >
                            {option?.case_status}
                          </Typography>
                        </Box>
                      </li>
                    )}
                    ListboxComponent={(props) => (
                      <ListboxComponent
                        {...props}
                        btnIcon={<ArrowOutwardRoundedIcon />}
                        btnText="Add Matter"
                        handleClikListBtn={handleMatterNewTab}
                      />
                    )}
                    noOptionsText={
                      <NoDataOptionField
                        imgSrc={noOption}
                        text="No matters found"
                        btnText="Matter"
                        handleClickNoData={() => navigate("/dashboard/matter")}
                      />
                    }
                  />
                </FormControl>
              )}
            />
            <Box sx={{ display: "flex", gap: "1rem" }}>
              <Controller
                name="source_account"
                control={control}
                render={({ field }) => (
                  <FormControl fullWidth>
                    <Autocomplete
                      disablePortal
                      options={AllAccounts?.filter(
                        (account) =>
                          account?.id !== watch("destination_account")
                      )}
                      getOptionLabel={(option) => option.account_name || ""}
                      value={
                        AllAccounts?.find((acc) => acc.id === field.value) ||
                        null
                      }
                      onChange={(_, selectedAccount) =>
                        field.onChange(
                          selectedAccount ? selectedAccount.id : null
                        )
                      }
                      renderOption={(props, option) => (
                        <li
                          {...props}
                          key={option.id}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Typography variant="body1">
                            {option.account_name}
                          </Typography>
                          <Box>
                            {option.is_default && (
                              <Chip
                                label="Default"
                                size="small"
                                color="primary"
                              />
                            )}
                          </Box>
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={
                            <span>
                              Source Account
                              <span style={{ color: "red" }}>*</span>
                            </span>
                          }
                          size="small"
                        />
                      )}
                    />
                  </FormControl>
                )}
              />

              <Controller
                name="destination_account"
                control={control}
                render={({ field }) => (
                  <FormControl fullWidth>
                    <Autocomplete
                      disablePortal
                      options={AllAccounts?.filter(
                        (account) => account.id !== watch("source_account")
                      )}
                      getOptionLabel={(option) => option.account_name || ""}
                      value={
                        AllAccounts?.find((acc) => acc.id === field.value) ||
                        null
                      }
                      onChange={(_, selectedAccount) =>
                        field.onChange(
                          selectedAccount ? selectedAccount.id : null
                        )
                      }
                      renderOption={(props, option) => (
                        <li
                          {...props}
                          key={option.id}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Typography variant="body1">
                            {option.account_name}
                          </Typography>
                          <Box>
                            {option.is_default && (
                              <Chip
                                label="Default"
                                size="small"
                                color="primary"
                              />
                            )}
                          </Box>
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={
                            <span>
                              Destination Account
                              <span style={{ color: "red" }}>*</span>
                            </span>
                          }
                          size="small"
                        />
                      )}
                    />
                  </FormControl>
                )}
              />
            </Box>
            <Box sx={{ display: "flex", gap: "1rem" }}>
              <Controller
                name="currency"
                control={control}
                render={({ field }) => (
                  <FormControl size="small" fullWidth>
                    <Autocomplete
                      size="small"
                      fullWidth
                      options={currencyList || []}
                      getOptionLabel={(option) => option?.label || ""}
                      value={
                        currencyList?.find(
                          (option) => option?.value === field?.value
                        ) || null
                      }
                      onChange={(_, newValue) =>
                        field.onChange(newValue ? newValue?.value : null)
                      }
                      isOptionEqualToValue={(option, value) =>
                        option?.value === value?.value
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Currency"
                          size="small"
                          fullWidth
                        />
                      )}
                    />
                  </FormControl>
                )}
              />
              <Controller
                name="amount"
                control={control}
                rules={{
                  required: "This field is required",
                  pattern: {
                    value: /^\d+(\.\d{1,2})?$/,
                    message: "Only numeric values allowed",
                  },
                  validate: (value) => {
                    const numericValue = parseFloat(value);
                    if (isNaN(numericValue)) return "Invalid amount";

                    const sourceAccount = AllAccounts?.find(
                      (acc) => acc.id === watch("source_account")
                    );
                    const availableBalance = sourceAccount?.balance ?? 0;

                    if (numericValue > availableBalance) {
                      return `Insufficient funds. Available: ${availableBalance}$`;
                    }
                    return true;
                  },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    size="small"
                    label={
                      <span>
                        Amount
                        <span style={{ color: "red" }}>*</span>
                      </span>
                    }
                    error={!!errors.amount}
                    helperText={errors.amount?.message}
                    inputProps={{
                      inputMode: "decimal",
                    }}
                    onInput={(e) => {
                      e.target.value = e.target.value.replace(/[^0-9.]/g, "");
                      if (e.target.value.split(".").length > 2) {
                        e.target.value = e.target.value.slice(0, -1);
                      }
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AttachMoneyRoundedIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Box>
            <Box sx={{ display: "flex", gap: "1rem" }}>
              <Controller
                name="exchange_rate" //this input use for the set the exchange rate of two diff currency rate inr to usd
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Exchange Rate"
                    size="small"
                    fullWidth
                  />
                )}
              />
              <Controller
                name="payer/payee"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Payer/Payee"
                    size="small"
                    fullWidth
                  />
                )}
              />
            </Box>
            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  multiline
                  rows={1.5}
                  fullWidth
                  label="Description"
                />
              )}
            />
          </Box>
          <Box
            sx={{
              height: "fit-content",
              borderTop: "1px solid #ddd",
              position: "sticky",
              bottom: 0,
              backdropFilter: "blur(10px)",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              p: "0.5rem",
              zIndex: 1000,
              gap: "0.5rem",
            }}
          >
            <Button variant="outlined" onClick={() => closeDialog()}>
              Cancel
            </Button>
            <LoadingButton
              //   loading={submitLoading}
              variant="contained"
              color="primary"
              type="submit"
            >
              Record transfer fund
            </LoadingButton>
          </Box>
        </form>
      </Box>
    </>
  );
};

export default TranferFundFrom;
