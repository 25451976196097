import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box } from "@mui/material";
import { pageScrollStyle } from "../styles/styles";

export default function DataTable({
  rows,
  columns,
  CustomToolbar,
  CustomNoRowsOverlay,
  height,
  loading,
  checkboxSelection,
  onSelectionChange,
}) {
  const [selectedRows, setSelectedRows] = useState([]);

  return (
    <Box
      sx={{
        height: height || 400,
        width: "100%",
        "& .MuiDataGrid-root": {
          overflowX: "auto",
        },
      }}
    >
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={10}
        loading={loading}
        checkboxSelection={checkboxSelection} //  Enables checkboxes for row selection
        onRowSelectionModelChange={(ids) => {
          const selectedRows = rows.filter((row) => ids.includes(row.id));
          onSelectionChange(selectedRows); // Pass selected rows data to parent
        }}
        disableRowSelectionOnClick
        disableSelectionOnClick
        columnVisibilityModel={{}}
        disableColumnResize={false}
        initialState={{
          pinnedColumns: {
            left: ["desk"],
          },
        }}
        slotProps={{
          loadingOverlay: {
            variant: "skeleton",
            noRowsVariant: "skeleton",
            rowCount: 5,
          },
        }}
        slots={{
          toolbar: () => <CustomToolbar selectedRows={selectedRows} />, // ✅ Pass selected rows to toolbar
          noRowsOverlay: CustomNoRowsOverlay,
        }}
        sx={{
          ...pageScrollStyle,
          border: "1px solid #e5e5e5",
          ".MuiDataGrid-cell": {
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            borderBottom: "1px solid #e5e5e5",
          },
          "& .MuiDataGrid-columnHeader": {
            backgroundColor: "#00b3b02c",
          },
          "& .MuiDataGrid-columnSeparator": {
            display: "none",
          },
          "& .MuiDataGrid-main": {
            overflow: "auto",
            scrollbarWidth: "thin",
            scrollBehavior: "smooth",
          },
          "& .MuiDataGrid-virtualScroller": {
            overflow: "auto",
            scrollBehavior: "smooth",
          },
        }}
      />
    </Box>
  );
}
